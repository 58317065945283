import FieldType from '@constants/field-type.js'

export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        disabled: true,
      },
      {
        name: 'video_quality',
        required: true,
        type: 'list-one-simple',
        options: ['low', 'medium', 'high'],
        trackBy: null,
        selectLabel: null,
      },
      {
        name: 'video_size',
        required: true,
        type: 'list-one-simple',
        options: ['small', 'medium', 'large'],
        trackBy: null,
        selectLabel: null,
      },
      {
        name: 'square_logo_id',
        label: 'General image',
        required: false,
        display_name: 'Square logo',
        type: FieldType.BASE_MEDIA,
        help_text: 'Image size in pixels: 200x200 (width, height)',
      },
      {
        name: 'rectangle_logo_id',
        required: false,
        display_name: 'Rectangle logo',
        type: FieldType.BASE_MEDIA,
        help_text: 'Image size in pixels: 70x22 (width, height)',
      },
      {
        name: 'tv_logo_id',
        required: false,
        display_name: 'TV logo',
        type: 'base_media',
        help_text: 'Image size in pixels: 161x189 (width, height)',
      },
      {
        name: 'laptop_logo_id',
        required: false,
        display_name: 'Laptop logo',
        type: 'base_media',
        help_text: 'Image size in pixels: 54x40 (width, height)',
      },
    ]
  },
  getConfig() {
    return {
      url: 'sports/providers',
      name: 'Provider',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
