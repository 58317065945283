<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div class="card" v-else>
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure.list"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
      :on-remove="remove"
      :extra-item-title="operator.name"
      :supportTypeFields="supportTypeFields"
      :supportTypeFieldValues="supportTypeFieldValues"
      :paymentLimitFields="payoutLimitsFields"
      :paymentLimitFieldsValues="payoutLimitsFieldValues"
      :sportsBookFieldValues="sportsBookFieldValues"
      :sportsBookFields="sportsBookFields"
      :gameFields="gameFields"
      :gameFieldValues="gameFieldValues"
    />
  </div>
</template>

<script setup>
import { ref, reactive, watch, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import Bonus from '@models/Bonus.js'
import BonusesService from '@services/BonusesService.js'
import { get } from 'jquery'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

const object = ref({})
const structure = reactive({ list: [] })
const config = Bonus.getConfig()
const bonusId = ref(null)
const isLoading = ref(true)
const form = ref(null)

const sportsBookFields = Bonus.getSportsBookFields()
const sportsBookFieldValues = ref([])

const supportTypeFields = Bonus.getSupportTypeFields()
const supportTypeFieldValues = ref([])
const payoutLimitsFields = Bonus.getPaymentLimitFields()
const payoutLimitsFieldValues = ref([])

const gameFields = Bonus.getGameFields()
const gameFieldValues = ref([])

const store = useStore()
const operator = computed(() => ({ ...store.state.editPage.model }))

const route = useRoute()
const router = useRouter()

const { proxy } = getCurrentInstance()

const getMarketsData = () => {
  isLoading.value = true
  BonusesService.getOne(bonusId.value, {
    with: 'market,game_type,sportCategories,bettingTypes,oddsTypes,betting_features,supportTypes,availableMarkets,exampleTopMarket,topFiveMarkets,availableEvents',
  })
    .then((response) => {
      object.value = response.data.result[0]
      structure.list = Bonus.getStructure(route?.params?.id).list
      getSportBookFieldValues()
      getSupportTypeFieldValues()
      //getPayoutLimitsFieldValues()
      getGameFieldValues()
      getPaymentLimitFieldsFieldValues()
    })
    .finally(() => {
      isLoading.value = false
    })
}

// Populate sports book field values
const getSportBookFieldValues = () => {
  console.log('object.value', object.value)
  const fields = []
  fields['betting_features'] = object.value.betting_features
    ? object.value.betting_features.map((item) => item.id)
    : []
  fields['sport_categories'] = object.value.sport_categories
    ? object.value.sport_categories.map((item) => item.id)
    : []
  fields['betting_types'] = object.value.betting_types
    ? object.value.betting_types.map((item) => item.id)
    : []
  fields['odds_types'] = object.value.odds_types ? object.value.odds_types.map((item) => item.name) : []
  fields['available_markets'] = object.value.available_markets
    ? object.value.available_markets.map((item) => item.id)
    : []
  fields['sport_category_id'] = object.value.sport_category_id
  
  // Remove example top market from the object since we use sport_category_id instead
  delete object.value.example_top_market;

  fields['top_five_markets'] = object.value.top_five_markets
    ? object.value.top_five_markets.map((item) => item.id)
    : []
  fields['total_number_of_sports'] = object.value.total_number_of_sports
  fields['available_events'] = object.value.available_events
    ? object.value.available_events.map((item) => item.id)
    : []
  sportsBookFieldValues.value = fields
}

const submit = async () => {
  form.value.isLoading = true

  object.value = {
    ...object.value,
    ...supportTypeFieldValues.value,
    ...payoutLimitsFieldValues.value,
    ...sportsBookFieldValues.value,
    ...gameFieldValues.value,
  }
  try {
    const response = await form.value.process(object.value)
    const updateResponse = await BonusesService.update(response)

    if (updateResponse.data.success && updateResponse.data.status === 1) {
      const entityName = structure?.config?.name || 'Market Data'
      proxy.showSuccessMessage(`${entityName} updated`)
    } else if (updateResponse.data.messages) {
      proxy.showErrorMessages(updateResponse.data.messages)
    } else {
      proxy.showUnknownErrorMessage()
    }
  } catch (error) {
    console.log('Error during submission:', error)
    proxy.showUnknownErrorMessage()
  } finally {
    form.value.isLoading = false
  }
}

const remove = async () => {
  if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
    try {
      const response = await BonusesService.remove({ id: bonusId.value })

      if (response.data.messages) {
        proxy.showErrorMessages(response.data.messages)
      } else if (response.data.success) {
        const name = structure?.config?.name || 'Item'
        proxy.showSuccessMessage(`${name} removed`)
        router.push(`/operators/${route.params.id}/markets-data`)
      } else {
        proxy.showErrorMessages(['Unexpected response from server'])
      }
    } catch (error) {
      console.error('Error in remove:', error)
      proxy.showUnknownErrorMessage(error)
    }
  }
}

const getSupportTypeFieldValues = () => {
  if (!object.value) return

  const fields = []

  fields.support_email = object.value.support_email
  fields.customer_support_channels = object.value.customer_support_channels
  fields.customer_support_availability = object.value.customer_support_availability
  fields.phone = object.value.phone
  fields.on_page_support = object.value.on_page_support
  fields.support_types = object.value.support_types
  supportTypeFieldValues.value = fields
}

const getPaymentLimitFieldsFieldValues = () => {
  if (!object.value) return
  const fields = []
  fields['min_deposit'] = object.value.min_deposit
  fields['min_withdrawal'] = object.value.min_withdrawal
  fields['max_withdrawal'] = object.value.max_withdrawal
  fields['max_deposit'] = object.value.max_deposit
  fields['average_deposit_time'] = object.value.average_deposit_time
  fields['average_deposit_fees'] = object.value.average_deposit_fees
  fields['average_deposit_fee_amount'] = object.value.average_deposit_fee_amount
  fields['average_withdrawal_times'] = object.value.average_withdrawal_times
  fields['average_withdrawal_fees'] = object.value.average_withdrawal_fees
  fields['average_withdrawal_fee_amount'] = object.value.average_withdrawal_fee_amount

  payoutLimitsFieldValues.value = fields
}

const getGameFieldValues = () => {
  if (!object.value) return

  const fields = []
  fields.number_of_slots = object.value.number_of_slots
  fields.number_of_jackpot_games = object.value.number_of_jackpot_games
  fields.number_of_table_games = object.value.number_of_table_games
  fields.number_of_live_games = object.value.number_of_live_games
  fields.number_of_live_software_providers = object.value.number_of_live_software_providers

  gameFieldValues.value = fields
}

watch(
  () => route.params.marketId,
  (newVal) => {
    if (newVal) {
      bonusId.value = newVal
      getMarketsData()
    }
  },
  { immediate: true }
)
</script>
