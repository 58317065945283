<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk editing {{ ids.length }} toplists</h5>
          <button class="close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="callout callout-info" v-if="!deleteOperators && !sortOperators && !isOperatorsToplistRoute">
            Please select an action: Sorting or Deleting
          </div>
          <base-checkbox
            v-if="!isOperatorsToplistRoute"
            v-model="sortOperators"
            name="sortOperators"
            :label="'Enable sorting options'"
            @change="toggleSortOperators"
          />
          <div v-if="sortOperators && !isOperatorsToplistRoute" class="sorting-options">
            <base-radio
              v-model="selectedSortingOption"
              name="sortingOption"
              :value="'ascending'"
              label="Select a sorting option:"
              :data="['Highest Rating', 'Newly added', 'Recently updated']"
            />
          </div>
          <base-checkbox
            v-if="sortOperators"
            v-model="confirmSorting"
            name="confirmSorting"
            :label="'Confirm sorting'"
          />
          <div class="callout callout-info" v-if="sortOperators">
            Please confirm this toplist does not have paid positions before sorting. Toplists with paid
            positions should not be sorted.
          </div>
          <base-checkbox
            v-model="deleteOperators"
            name="deleteOperators"
            :label="`Delete toplists`"
            @change="toggleDeleteOperators"
          />
          <div class="callout callout-info" v-if="deleteOperators && isOperatorsToplistRoute">
            This will permanently delete this operator: {{ operatorName }} from the selected toplists
          </div>
          <div class="callout callout-info" v-if="deleteOperators && !isOperatorsToplistRoute">
            This will permanently delete the selected toplists
          </div>
        </div>
        <div class="modal-footer d-flex">
          <button class="btn btn-secondary" :class="{ loading: loading }" @click="close">Cancel</button>
          <button
            class="btn btn-primary ml-2"
            :class="{ loading: loading }"
            :disabled="!confirmSorting"
            @click="submitSorting"
            v-if="sortOperators"
          >
            Apply Sorting
          </button>
          <button
            class="btn btn-danger ml-2"
            :class="{ loading: loading }"
            @click="remove()"
            v-if="deleteOperators && !isOperatorToplist"
          >
            Delete
          </button>
          <button
            class="btn btn-primary ml-2"
            :class="{ loading: loading }"
            @click="submit()"
            v-if="isOperatorToplist && !sortOperators"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopListsService from '@services/TopListsService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: { BaseCheckbox, BaseRadio },
  data() {
    return {
      ids: [],
      loading: false,
      toplist: null,
      deleteOperators: false,
      sortOperators: false,
      selectedSortingOption: null,
      confirmSorting: false,
    }
  },
  props: {
    stateName: {
      type: String,
      default: () => 'data-table',
    },
    isOperatorToplist: {
      type: Boolean,
      default: false,
    },
    isOperatorsToplistRoute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    operatorName() {
      return this.$store.state.editPage?.model?.name
    },
  },
  methods: {
    toggleDeleteOperators() {
      if (this.deleteOperators) {
        this.sortOperators = false
        this.selectedSortingOption = null
        this.confirmSorting = false
      }
    },
    toggleSortOperators() {
      if (this.sortOperators) {
        this.deleteOperators = false
        this.selectedSortingOption = null
        this.confirmSorting = false
      }
    },
    show(ids) {
      this.ids = ids
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    async submitSorting() {
      if (!this.selectedSortingOption) {
        this.showErrorMessages('Please select a sorting option')
        return
      }
      this.loading = true
      await TopListsService.bulkUpdate({
        ids: this.ids,
        sortingOption: this.selectedSortingOption.value,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.clearBulkSelection()
            this.showSuccessMessage(`Sorting applied`)
            this.$emit('refresh')
            this.close()
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async remove() {
      if (this.ids.length > 10) {
        return this.showErrorMessages('You cannot delete more than 10 toplists at a time')
      }
      if (
        await confirmDelete('Warning', `Are you sure you want to delete ${this.ids.length} toplists?`, 'Yes')
      ) {
        this.loading = true
        await TopListsService.bulkRemove({ ids: this.ids })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.clearBulkSelection()
              this.showSuccessMessage('Toplists deleted')
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => {
            this.showUnknownErrorMessage()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async submit() {
      if (!this.deleteOperators) {
        this.showErrorMessages('Please add/choose values for bulk actions')
      } else {
        if (
          await confirmDelete(
            'Warning',
            `Are you sure you want to remove this operator from toplists?`,
            'Yes'
          )
        ) {
          this.loading = true
          await TopListsService.deleteOperator({ ids: this.ids })
            .then((response) => {
              if (response.data.messages) {
                this.showErrorMessages(response.data.messages)
              } else {
                this.clearBulkSelection()
                this.showSuccessMessage(`${this.operatorName} removed from selected toplists`)
                this.$emit('refresh')
                this.close()
              }
            })
            .catch(() => {
              this.showUnknownErrorMessage()
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>
