import Api from '../Api'
import App from '@/main.js'

export default {
  get() {
    const user = App.config.globalProperties.$auth.user()

    return Api.get(`koala/v1/account-jobs?hercules_user_id=${user.id}&hercules_user_name=${user.name}`)
  },
  post(data) {
    const user = App.config.globalProperties.$auth.user()

    const requestData = {
      hercules_user_id: user.id,
      hercules_user_name: user.name,
      dry_run: data.dry_run,
    }

    if (data.platform_ids && data.platform_ids.length > 0) {
      requestData.platform_ids = data.platform_ids
    } else if (data.operator_platform_ids && data.operator_platform_ids.length > 0) {
      requestData.platform_ids = [data.operator_platform_ids]
    }
    if (data.operator_ids && data.operator_ids.length > 0) requestData.operator_ids = data.operator_ids
    if (data.business_unit_ids && data.business_unit_ids.length > 0)
      requestData.business_unit_ids = data.business_unit_ids
    if (data.account_ids && data.account_ids.length > 0) requestData.account_ids = data.account_ids
    if (data.report_ids && data.report_ids.length > 0) requestData.report_ids = data.report_ids

    if (data.date_range) {
      let [start_date, end_date] = data.date_range.split(' to ')
      if (!end_date) {
        end_date = start_date
      }
      requestData.date_range = {
        start_date,
        end_date,
      }
    }
    return Api().post('koala/v1/account-jobs', requestData)
  },
}
