<template>
  <div v-if="loadingElements">Loading...</div>
  <div v-else>
    <div v-bind:key="index" v-for="(moduleField, index) in pageModule.module_value_fields">
      <page-select-modal
        v-show="checkMultiSelect === false || checkPageTypeSelector === false"
        v-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_selector_manual_list'
        "
        :value="moduleField.value === null ? '' : moduleField.value"
        @valuePicked="(value) => selectedPages(moduleField, value)"
        :parameters="pageSelectFieldGetParams"
        :pageTypeId="pageType ? pageType.id : null"
        :type="pageType ? pageType.type : 'Page'"
        customLabel="Cards"
        tableClass="table table-sm"
        tableWrapperClass="card-body px-2 py-0 px-0"
        :showFilter="pageType ? true : false"
      />
      <base-radio
        v-show="checkPageTypeSelector === true"
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_v2_page_type_selector'
        "
        :data="options"
        :label="moduleField.field.label"
        :modelValue="moduleField.value"
        @update:modelValue="(option) => pageTypeSelectorChanged(option.value)"
        :inlineLayout="true"
        name="Page Type Selector"
      />
      <cards-v2-filters
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_selector_filters'
        "
        :parameters="{ site_id: siteId, market_id: marketId, match_market_language: 1 }"
        :value="cardsFilters"
        :pageType="pageType"
        @change="(value) => (cardsFilters = value)"
      />
      <base-multiselect
        v-show="checkMultiSelect === true && checkPageTypeSelector === true"
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_page_type_multiselect'
        "
        :field="moduleField.field.name"
        :label="moduleField.field.label"
        :path="`hercules/sites/templates?site_id=${siteId}&is_filterable=1&orderBy=name&ascending=1`"
        :multiple="true"
        placeholder="Select one or more options"
        :value="cardPageType(moduleField.value)"
        @sync="
          (field, value) => (moduleField.value = Array.isArray(value) ? value.map((n) => n.id).join(',') : '')
        "
      />

      <base-select
        v-show="checkMultiSelect === false || checkPageTypeSelector === false"
        :label="moduleField.field.name"
        v-else-if="
          (visibleFields === null || visibleFields.includes(moduleField.field.name)) &&
          moduleField.field.name === 'cards_page_type'
        "
        :modelValue="moduleField.value"
        v-model="moduleField.value"
        :path="`hercules/sites/templates?site_id=${siteId}&is_filterable=1&orderBy=name&ascending=1`"
        option-label-key="name"
        emitOption="type"
        placeholder="Select page type"
        @update:modelValue="(value, option) => getPageType(value)"
        :clearable="true"
        helpText="Leave empty to select from all page types"
      />
      <page-edit-module-field
        v-else-if="
          moduleField.field.name !== 'cards_selector_manual_list' &&
          moduleField.field.name !== 'cards_selector_filters'
        "
        :index="index"
        :siteId="siteId"
        :affiliateId="affiliateId"
        :marketId="marketId"
        :moduleField="moduleField"
        :visibleFields="visibleFields"
        :checkMultiSelect="checkMultiSelect"
        :checkPageTypeSelector="checkPageTypeSelector"
      />
    </div>
  </div>
</template>
<script>
import PageSelectModal from '@molecules/fields/page-multi-select/multi-select-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import DynamicFilters from '@/components/fields/dynamic-filters.vue'
import CardsV2Filters from '@/components/atomic/molecules/cms/modules/cards-v2-filters.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModuleGroupAddItem from '@atoms/cms/modules/module-group-add-item.vue'
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'
import TemplateService from '@services/TemplatesService.js'

export default {
  components: {
    PageSelectModal,
    BaseSelect,
    BaseMultiselect,
    BaseInput,
    ModuleGroupAddItem,
    BaseRadio,
    PageEditModuleField,
    DynamicFilters,
    CardsV2Filters,
  },
  props: {
    siteId: {
      required: false,
      default: null,
    },
    marketId: {
      required: false,
      default: null,
    },
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
    affiliateId: {},
  },
  inject: ['getTemplateId'],
  async beforeMount() {
    // Ensure getTemplates completes before proceeding
    await this.getTemplates()
    // Load page type, templates, and page types multi-select before mount
    await this.getPageType(this.findFieldByName(this.pageModule, 'cards_page_type').value, false)
  },
  data() {
    return {
      loadingElements: false,
      pageType: null,
      page_type_select: null,
      options: ['Single Select', 'Multi Select'],
      checkMultiSelect: false,
      checkPageTypeSelector: false,
    }
  },
  computed: {
    cardsFilters: {
      get: function () {
        return JSON.parse(this.findFieldByName(this.pageModule, 'cards_selector_filters').value)
      },
      set: function (newValue) {
        this.findFieldByName(this.pageModule, 'cards_selector_filters').value = JSON.stringify(newValue)
      },
    },
    cardsSelector() {
      return this.findFieldByName(this.pageModule, 'cards_selector').value
    },

    isMultiSelect() {
      return this.checkMultiSelect
    },
    visibleFields() {
      let visibleFields = [
        'cards_title',
        'cards_link',
        'cards_v2_page_type_selector',
        'cards_page_type',
        'cards_page_type_multiselect',
        'cards_selector',
        'cards_enable_pagination',
        'cards_v2_extra_data',
        'see_all_card_enabled',
      ]

      // Related to the new fields added for AG migration
      const getAllCardEnabledField = this.findFieldByName(this.pageModule, 'see_all_card_enabled')
      if (getAllCardEnabledField && getAllCardEnabledField.value && getAllCardEnabledField.value !== '0') {
        visibleFields = visibleFields.concat([
          'see_all_card_text',
          'see_all_card_image',
          'see_all_card_button_label',
          'see_all_card_button_link',
        ])
      }

      if (this.isMultiSelect) {
        visibleFields = visibleFields.concat([
          'cards_selector_filters_limit',
          'cards_selector_filters_sort_by',
        ])
      }

      if (this.cardsSelector === 'select_manually') {
        visibleFields = visibleFields.concat(['cards_selector_manual_list'])
      } else if (this.cardsSelector === 'use_filters') {
        visibleFields = visibleFields.concat([
          'cards_selector_filters_limit',
          'cards_selector_filters',
          'cards_selector_filters_sort_by',
        ])
      }

      return visibleFields
    },
    showAddPages() {
      return this.pageType ? true : false
    },
    pageSelectFieldGetParams() {
      let params = {
        site_id: this.siteId,
        market_id: this.marketId,
        affiliate_id: this.affiliateId,
        match_market_language: 1,
        status: 'active',
      }
      if (this.pageType) {
        params.type = this.pageType.type
      }
      if (this.page_type_select) {
        params.page_type_select = this.page_type_select
      }
      return params
    },
  },
  methods: {
    cardPageType(value) {
      const numberStringPattern = /^(\d+,)*\d+$/
      if (typeof value === 'string' && numberStringPattern.test(value)) {
        return value.split(',').map(Number)
      }
      return value
    },
    pageTypeSelectorChanged(type) {
      this.findFieldByName(this.pageModule, 'cards_v2_page_type_selector').value = type
      switch (type) {
        case 'Single Select':
          this.checkMultiSelect = false
          break
        case 'Multi Select':
          this.checkMultiSelect = true
          break
      }
    },
    async getPageType(id, resetFilters = true) {
      if (resetFilters) {
        // only maintain non page type specific filters
        this.cardsFilters = {
          page_authors: this.cardsFilters?.page_authors,
          page_reviewer: this.cardsFilters?.page_reviewer,
        }
        // manual list needs resetting because it is based on page type
        this.findFieldByName(this.pageModule, 'cards_selector_manual_list').value = null
      }
      if (!id) {
        this.pageType = null
      } else {
        TemplateService.getOne(this.findFieldByName(this.pageModule, 'cards_page_type').value)
          .then((response) => {
            if (response.data.success) {
              this.pageType = response.data.result[0]
            }
          })
          .catch(() => {
            this.showErrorMessage('Failed to get page type')
          })
      }
    },
    selectedPages(moduleField, value) {
      moduleField.value = value
    },
    findFieldByName(pageModule, name) {
      return pageModule.module_value_fields.find((item) => item.field.name === name)
    },
    //need to do this call to get all items, then implement conditions to show if any of items has enable_cards_v2_multiple_selections
    async getTemplates() {
      await TemplateService.getOne(this.getTemplateId)
        .then((response) => {
          if (response.data.success) {
            response.data.result.forEach((item) => {
              if (item.enable_cards_v2_multiple_selections === 1 && item.short_name === 'homepage') {
                this.checkPageTypeSelector = true
                const pageTypeselector = this.findFieldByName(this.pageModule, 'cards_v2_page_type_selector')
                if (
                  pageTypeselector &&
                  pageTypeselector.value !== null &&
                  pageTypeselector.value === 'Multi Select'
                ) {
                  this.checkMultiSelect = true
                }
              }
            })
            if (this.checkPageTypeSelector) {
              this.populateCardsPageTypeMultiselect()
            }
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to get page type')
        })
    },
    populateCardsPageTypeMultiselect() {
      let ids
      const multiSelectField = this.findFieldByName(this.pageModule, 'cards_page_type_multiselect')
      if (
        multiSelectField &&
        multiSelectField.value !== null &&
        multiSelectField.value !== '' &&
        typeof multiSelectField.value !== 'object'
      ) {
        ids = multiSelectField.value.toString().split(',')
      }
      if (ids) {
        TemplateService.get({ ids })
          .then((response) => {
            if (response.data.success) {
              this.page_type_select = response.data.result.map((item) => item.id)
              multiSelectField.value = response.data.result.map((item) => item.id).join(',')
            }
          })
          .catch(() => {
            this.showErrorMessage('Failed to get page types by ids')
          })
      }
    },
  },
}
</script>
