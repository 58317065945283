export default {
  state: () => ({
    advancedSearch: false,
    customActionButtonLoading: false,
    customSecondaryButtonLoading: false,
    searchQuery: '',
    limit: 10,
    visibleColumns: [],
    selecteditems: [],
    allItemsSelected: false,
    showCreate: false,
    siteFilters: {},
    activePage: 1,
    advancedFilters: {},
    order: null,
    memorizeQueries: {},
    title: '',
    standardisedLogo: null,
    isLoading: true,
    initFormObject: {},
    formObject: {},
    dateCleared: false,
    toplistParams: {},
    currentRoute: '',
  }),
  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    order: (state) => {
      return state.order
    },
    advancedFilters: (state) => {
      return state.advancedFilters
    },
    activePage: (state) => {
      return state.activePage
    },
    searchQuery: (state) => {
      return state.searchQuery
    },
    advancedSearch: (state) => {
      return state.advancedSearch
    },
    customActionButtonLoading: (state) => {
      return state.customActionButtonLoading
    },
    customSecondaryButtonLoading: (state) => {
      return state.customSecondaryButtonLoading
    },
    limit: (state) => {
      return state.limit
    },
    visibleColumns: (state) => {
      return state.visibleColumns
    },
    selecteditems: (state) => {
      return state.selecteditems
    },
    showCreate: (state) => {
      return state.showCreate
    },
    allItemsSelected: (state) => {
      return state.allItemsSelected
    },
    siteFilters: (state) => {
      return state.siteFilters
    },
    title: (state) => {
      return state.title
    },
    standardisedLogo: (state) => {
      return state.standardisedLogo
    },
  },
  actions: {
    setCurrentRoute({ commit }, value) {
      commit('setCurrentRoute', value)
    },
    setMemorizedQueries({ commit }, value) {
      commit('setMemorizedQueries', value)
    },
    setToplistParams({ commit }, params) {
      commit('setToplistParams', params)
    },
    setQueriesFromMemorized({ commit }) {
      commit('setQueriesFromMemorized')
    },
    clearQueries({ commit }) {
      commit('clearQueries')
    },
    setOrder({ commit }, value) {
      commit('setOrder', value)
    },
    setAdvancedFilters({ commit }, value) {
      commit('setAdvancedFilters', value)
    },
    setActivePage({ commit }, value) {
      commit('setActivePage', value)
    },
    setSearchQuery({ commit }, value) {
      commit('setSearchQuery', value)
    },
    setAdvancedSearch({ commit }, value) {
      commit('setAdvancedSearch', value)
    },
    setCustomActionButtonLoading({ commit }, value) {
      commit('setCustomActionButtonLoading', value)
    },
    setCustomSecondaryButtonLoading({ commit }, value) {
      commit('setCustomSecondaryButtonLoading', value)
    },
    setLimit({ commit }, value) {
      commit('setLimit', value)
    },
    setVisibleColumns({ commit }, value) {
      commit('setVisibleColumns', value)
    },
    setSelecteditems({ commit }, value) {
      commit('setSelecteditems', value)
    },
    setShowCreate({ commit }, value) {
      commit('setShowCreate', value)
    },
    setAllItemsSelected({ commit }, value) {
      commit('setAllItemsSelected', value)
    },
    resetBulk({ commit }) {
      commit('resetBulk')
    },
    setSiteFilters({ commit }, value) {
      commit('setSiteFilters', value)
    },
  },
  mutations: {
    setCurrentRoute(state, value) {
      state.currentRoute = value
    },
    setDateCleared(state, value) {
      state.dateCleared = value
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setInitFormObject(state, value) {
      state.initFormObject = value
    },
    setFormObject(state, value) {
      state.formObject = value
    },
    setMemorizedQueries(state, value) {
      if (value) {
        state.memorizeQueries = {
          searchQuery: state.searchQuery,
          limit: state.limit,
          selecteditems: state.selecteditems,
          activePage: state.activePage,
          advancedFilters: state.advancedFilters,
          order: state.order,
          currentRoute: state.currentRoute,
        }
      } else {
        state.memorizeQueries = {}
      }
    },
    setQueriesFromMemorized(state) {
      if (!Object.keys(state.memorizeQueries).length) return
      state.searchQuery = state.memorizeQueries.searchQuery
      state.limit = state.memorizeQueries.limit
      state.selecteditems = state.memorizeQueries.selecteditems
      state.activePage = state.memorizeQueries.activePage
      state.advancedFilters = state.memorizeQueries.advancedFilters
      state.order = state.memorizeQueries.order
      state.currentRoute = state.memorizeQueries.currentRoute
    },
    clearQueries(state) {
      state.searchQuery = ''
      state.limit = 10
      state.selecteditems = []
      state.activePage = 1
      state.advancedFilters = {}
      state.order = null
    },
    setOrder(state, value) {
      state.order = value
    },
    setToplistParams(state, value) {
      state.toplistParams = value
    },
    setAdvancedFilters(state, value) {
      state.advancedFilters = value
    },
    setActivePage(state, value) {
      state.activePage = value
    },
    setSiteFilters(state, value) {
      Object.keys(value).forEach((key) => {
        if (value[key] == null) {
          delete value[key]
        }
      })
      state.siteFilters = value
    },
    setSearchQuery(state, value) {
      state.searchQuery = value
    },
    setAdvancedSearch(state, value) {
      state.advancedSearch = value
    },
    setCustomActionButtonLoading(state, value) {
      state.customActionButtonLoading = value
    },
    setCustomSecondaryButtonLoading(state, value) {
      state.customSecondaryButtonLoading = value
    },
    setLimit(state, value) {
      state.limit = value
    },
    setVisibleColumns(state, value) {
      if (value instanceof Array) {
        state.visibleColumns = value
      } else {
        if (state.visibleColumns.includes(value)) {
          state.visibleColumns = state.visibleColumns.filter((item) => item !== value)
        } else {
          state.visibleColumns.push(value)
        }
      }
    },
    setSelecteditems(state, value) {
      if (value instanceof Array) {
        state.selecteditems = value
      } else {
        const index = state.selecteditems.indexOf(value)
        if (index !== -1) {
          state.selecteditems.splice(index, 1)
        } else {
          state.selecteditems.push(value)
        }
      }
    },
    setShowCreate(state, value) {
      state.showCreate = value
    },
    setAllItemsSelected(state, value) {
      state.allItemsSelected = value
    },
    resetBulk(state) {
      state.allItemsSelected = false
      state.selecteditems = []
    },
    setTitle(state, value) {
      state.title = value
    },
    setStandardisedLogo(state, value) {
      state.standardisedLogo = value
    },
  },
  namespaced: true,
}
