import Api from './Api'

const path = 'hercules/operators/bonuses'

export default {
  getAll(operatorId) {
    return Api().get(`${path}?operator_id=${operatorId}`)
  },
  post(data) {
    return Api().post(path, data)
  },
  update(data) {
    const payload = { ...data }

    if (!payload.average_deposit_fees || payload.average_deposit_fees === 0) {
      payload.average_deposit_fee_amount = null
    }

    if (!payload.average_withdrawal_fees || payload.average_withdrawal_fees === 0) {
      payload.average_withdrawal_fee_amount = null
    }

    if (!Array.isArray(payload.support_types) || !payload.support_types.includes(7)) {
      payload.support_email = null
    }

    if (!Array.isArray(payload.support_types) || !payload.support_types.includes(15)) {
      payload.phone = null
    }

    return Api().put(`${path}/${payload.id}`, payload)
  },
  remove(data) {
    return Api().delete(`${path}/${data.id}`)
  },
  getOne(id, data = {}) {
    return Api().get(`${path}/${id}`, { params: data })
  },
  // Bonus Types
  fetchTypes(bonusId, params = null) {
    return Api().get(`${path}/${bonusId}/types`, { params: params })
  },
  postType(bonusId, data) {
    return Api().post(`${path}/${bonusId}/types`, data)
  },
  updateType(id, data) {
    return Api().put(`${path}/${id}/types/${data.id}`, data)
  },
  deleteType(bonusId, typeId) {
    return Api().delete(`${path}/${bonusId}/types/${typeId}`)
  },
  fetchType(bonusId, typeId, params = null) {
    return Api().get(`${path}/${bonusId}/types/${typeId}`, { params: params })
  },
  fetchTypeStructure(id) {
    return Api().get(`${path}/${id}/types/structure`)
  },
  // Bonus Type Values
  deleteTypeValue(id, data) {
    return Api().delete(`${path}/${id}/types/${data['bonus_type_id']}/values/${data.id}`)
  },
  postTypeValue(bonusId, data) {
    return Api().post(`${path}/${bonusId}/types/${data['bonus_type_id']}/values`, data)
  },
  updateTypeValue(id, data) {
    return Api().put(`${path}/${id}/types/${data['bonus_type_id']}/values/${data.id}`, data)
  },
}
