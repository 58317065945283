<template>
  <div class="reply-wrapper">
    <div v-if="localReplies && localReplies.length > 0">
      <div class="d-block align-items-center gap-4 w-100" v-for="reply in localReplies">
        <div class="d-flex align-items-center justify-between gap-4">
          <div class="w-75">
            <base-select
              label="Author"
              :clearable="false"
              v-model="reply.author_id"
              ref="providerSelect"
              :loadOnMount="false"
              :path="`hercules/sites/authors?site_id=${siteId}`"
              option-label-key="name"
              class="pr-2 mb-2"
            />
          </div>
        </div>
        <div>
          <base-textarea label="Comments" ref="input" :rows="3" v-model="reply.comment" />
        </div>
        <div class="mb-2">
          <button
            class="btn btn-danger btn-sm mr-2"
            @click.stop="deleteReply(reply)"
            :class="{ loading: loadingReplyId === reply.id }"
          >
            <i class="uil uil-trash cursor-pointer"></i>Delete
          </button>
          <button
            type="submit"
            class="btn btn-primary btn-sm"
            @click.stop="updateFN(reply)"
            :class="{ loading: loadingReplyId === reply.id }"
          >
            <i class="uil uil-check"></i>
            Update
          </button>
        </div>
      </div>
    </div>
    <h4 class="mb-4" v-else>No replies added yet for this comment!</h4>
  </div>
</template>
<script setup>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import PageCommentService from '@services/PageCommentService.js'
import { ref, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const emits = defineEmits(['closeModal', 'replyChanged'])
const props = defineProps({
  replies: {
    type: Array,
    default: () => null,
  },
  siteId: {
    type: Number,
    default: () => null,
  },
})

const loadingReplyId = ref(null)
const localReplies = ref(props.replies || [])

const updateFN = async (reply) => {
  loadingReplyId.value = reply.id
  try {
    const response = await PageCommentService.update(reply)
    if (response.data.messages) {
      proxy.showErrorMessage(response.data.messages[0])
    } else {
      proxy.showSuccessMessage('Reply updated')
      closeModal()
      emits('replyChanged', reply)
    }
  } catch (error) {
    console.error(error)
  } finally {
    loadingReplyId.value = null
  }
}

const deleteReply = async (reply) => {
  loadingReplyId.value = reply.id
  try {
    const response = await PageCommentService.remove(reply)
    if (response.data.messages) {
      proxy.showErrorMessage(response.data.messages[0])
    } else {
      proxy.showSuccessMessage('Reply deleted')
      closeModal()
      emits('replyChanged', reply)
    }
  } catch (error) {
    console.error(error)
  } finally {
    loadingReplyId.value = null
  }
}

const closeModal = () => {
  emits('closeModal')
}
</script>
<style scoped>
.gap-4 {
  gap: 16px;
}
.modal-footer {
  padding: 0;
  margin-bottom: 16px;
}
.reply-wrapper {
  max-height: 680px;
  overflow-y: auto;
}
</style>
