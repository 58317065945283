<template>
  <node-view-wrapper v-bind="node.attrs" as="th" ref="tableHead">
    <node-view-content />
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  mounted() {
    this.$refs.tableHead?.$el.addEventListener('click', this.highlightHeader)
  },
  beforeUnmount() {
    this.$refs.tableHead?.$el.removeEventListener('click', this.highlightHeader)
  },
  props: nodeViewProps,
  methods: {
    highlightHeader() {
      const sel = getSelection().toString()
      if (sel.length) {
        this.$store.commit('tiptap/setSelectedText', sel)
      }
    },
  },
}
</script>
