<template>
  <div
    class="item id text-truncate"
    ref="scroll-item"
    @click="onClick(source)"
    @mouseover="onMouseOver"
    v-bind:class="{
      active: activeOptions.find((item) => item.id == source.id),
      'disabled-option': source.active == 0,
      'hovered-item': pointer === (source instanceof Object ? source.id : source),
      'active-item':
        pointer === (source instanceof Object ? source.id : source) &&
        activeOptions.find((item) => item.id == source.id),
      'active-item-remove':
        pointer === (source instanceof Object ? source.id : source) &&
        !activeOptions.find((item) => item.id == source.id),
    }"
  >
    {{ source[labelKey] || source.name || 'Unknown' }}
    <span v-if="resolvedLabel" class="extra-label"> - {{ resolvedLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'scroll-item',
  inject: ['onChangeItem', 'labelKey', 'pointer', 'activeOptions', 'resolveExtraOptionLabels'],
  props: {
    source: {
      type: Object,
      required: true, // Each item in the array is an object
    },
  },
  data() {
    return {
      number: 0,
    }
  },
  computed: {
    resolvedLabel() {
      return this.resolveExtraOptionLabels ? this.resolveExtraOptionLabels(this.source) : null
    },
  },
  methods: {
    onClick(source) {
      this.onChangeItem(source)
    },
    onMouseOver() {
      this.$refs['scroll-item'].parentElement?.parentElement?.children?.forEach((child) => {
        if (child && child.children.length) {
          return child.children[0].classList.remove('hovered-item')
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.item {
  padding: 1em;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f6f6f6;
    color: #17182f;
  }

  &:hover::after {
    position: absolute;
    right: 10px;
    background-color: #f6f6f6;
    color: #17182f;
    content: 'Click to add' !important;
  }
}

.active,
.active-item {
  background-color: #f6f6fb;
  color: #17182f !important;
  // When hovered, prioritize hover behavior
  &:hover::after {
    position: absolute;
    right: 10px;
    content: 'Click to remove' !important;
  }
}

.active-item::after,
.active-item-remove::after {
  position: absolute;
  right: 10px;
}

.hovered-item:not(.item):hover {
  background-color: #f6f6fb !important;
  color: #17182f;
}

.hovered-item {
  background-color: #f6f6f6 !important;
  color: #17182f;

  &::after {
    position: absolute;
    right: 10px;
    content: 'Press enter to select' !important;
  }

  &.active-item::after {
    content: 'Press enter to remove' !important;
  }
}
</style>
