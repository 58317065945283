<template>
  <data-table
    service-file="AffiliateAdvancedGameRatingsService"
    model="AffiliateAdvancedGameRatings"
    :path="path"
    ref="list"
    :columns="columns()"
    :columnTemplates="columnTemplates()"
    :serviceParams="[affiliate_id]"
    :create-defaults="{ affiliate_id: affiliate_id }"
  />
</template>

<script setup>
import { getCurrentInstance, ref, onMounted, computed } from 'vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()
const { proxy } = getCurrentInstance()
const affiliate_id = ref(route.params['id'])

onMounted(() => {
  proxy.setTitle(`${store.state.editPage.model ? store.state.editPage.model.website + ':' : ''} Markets Data`)
})

  const columns = () => {
  return [
    { field: 'rating_name', type: 'text', filterable: true },
    { field: 'market', type: 'text', label: 'Market', filterable: true },
    { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
  ]
}
  const path = computed(
  () =>
      `hercules/games/advanced-ratings?affiliate_id=${route.params['id']}&with=markets`
  )

  const columnTemplates = () => {
  return {
    market(row) {
      return row.markets?.map((market) => market.label).join(', ')
    }
  }
}
</script>
