<template>
  <div>
    <data-table
      model="cms/Menu"
      service-file="MenusService"
      path="hercules/sites/menus?with=site,market"
      :columns="columns()"
      ref="list"
      :show-edit-modal="false"
      :column-templates="columnTemplates()"
      :rowActionButtons="rowActions()"
    />

    <menu-clone ref="menuClone" v-on:refresh="refresh" />
  </div>
</template>

<script>
import BasicOptions from '@mixins/BasicOptions.js'
import TableColumnTemplate from '@constants/table-column-template.js'
import MenuClone from '@molecules/cms/menus/clone-modal.vue'

export default {
  components: { MenuClone },
  created() {
    this.setTitle()
  },
  methods: {
    rowActions() {
      return [
        {
          action: this.menuCloneAction,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'bg-orange-75',
        },
      ]
    },
    columns() {
      return [
        { field: 'title', type: 'text', filterable: true, showModalAdvanced: false },
        { field: 'short_code', type: 'text', filterable: true, showModalAdvanced: false, sortable: false },
        { field: 'site', type: 'text', label: 'Site', filterable: true, showModalAdvanced: false },
        { field: 'market', type: 'text', label: 'Market', filterable: true, showModalAdvanced: false },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        site: function (row) {
          return row.site.name
        },
        market: function (row) {
          return row.market.label
        },
      }
    },
    menuCloneAction(row) {
      this.$refs.menuClone.show(row)
    },
    refresh() {
      this.$refs?.list?.search()
    },
  },
}
</script>
