<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Rating to {{ row.operator.name }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <base-average
            :key="baseAverage"
            ref="baseAverage"
            :value="inputRatings"
            label="Rating"
            :field="{
              name: 'rating',
              display_name: 'Rating',
              type: 'average_number',
              average_input: 'rating',
              inputs: ['rating_casino', 'rating_games', 'rating_bonuses', 'rating_customer', 'rating_payout'],
              min: 0,
              max: 5,
              step: 0.1,
            }"
            :showAverage="true"
            :showAverageNull="false"
            :fieldType="'number'"
            :sanitizeLabel="true"
            :allow_text="false"
            :required="false"
          />
        </div>
        <div class="modal-footer pt-3">
          <div class="w-100 text-right">
            <button
              type="submit"
              v-if="row.operator.affiliate_operators[0] && row.operator.affiliate_operators[0] !== ''"
              class="btn btn-primary"
              :class="{ loading: loading }"
              @click="updateRatingsModal"
            >
              <i class="uil uil-check"></i>
              Update
            </button>
            <button
              v-else
              type="submit"
              class="btn btn-primary"
              :class="{ loading: loading }"
              @click="submitRatingsModal"
            >
              <i class="uil uil-check"></i>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BaseAverage from '@molecules/fields/base-average.vue'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService'

export default {
  components: {
    BaseAverage,
  },
  data: function () {
    return {
      loading: false,
      showModal: false,
      inputRatings: {
        rating_casino: null,
        rating_games: null,
        rating_bonuses: null,
        rating_customer: null,
        rating_payout: null,
        rating: null,
      },
      initialRatings: {},
      baseAverage: 0,
    }
  },
  props: {
    index: Number,
    row: Object,
    list: Object,
    storeName: String,
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    buildUpdateObject() {
      let payload = {
        id: this.row.operator.affiliate_operators[0].id,
        rating: this.inputRatings.rating,
      }
      Object.keys(this.inputRatings).forEach((key) => {
        if (this.inputRatings[key] !== this.initialRatings[key]) {
          payload[key] = this.inputRatings[key]
        }
      })
      return payload
    },
    initializeRatings() {
      if (this.row && this.row.operator.affiliate_operators[0]) {
        const operator = cloneDeep(this.row.operator.affiliate_operators[0])
        Object.keys(this.inputRatings).forEach((key) => {
          this.inputRatings[key] = operator[key] || null
        })
        if (operator.rating !== null && operator.rating !== undefined) {
          this.inputRatings.rating = operator.rating
        }
        this.initialRatings = cloneDeep(this.inputRatings)
        this.$nextTick(() => {
          this.$refs.baseAverage.calculateAverage()
        })
      }
    },
    show() {
      this.initializeRatings()
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    async updateRatings() {
      this.loading = true
      const updateData = this.buildUpdateObject()
      try {
        const response = await AffiliateOperatorsService.update(updateData)
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          this.showSuccessMessage('Affiliate operator ratings updated')
        }
      } catch (error) {
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
      }
    },
    async submitModal() {
      this.loading = true
      let payload = {
        affiliate_id: this.list.affiliate_id,
        market_id: this.list.market_id,
        type: this.list.operator_type,
        operator_id: this.row.operator_id,
        rating: this.inputRatings.rating || null,
      }
      Object.keys(this.inputRatings).forEach((key) => {
        payload[key] = this.inputRatings[key] || null
      })
      try {
        const response = await AffiliateOperatorsService.post(payload)
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          response.data.result.types = []
          response.data.result.links = []
          this.$store.commit(`${this.storeName}/createSitesData`, {
            result: [response.data.result],
            item: this.row,
          })
          this.showSuccessMessage('Affiliate operator ratings added successfully')
        }
      } catch (error) {
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
      }
    },
    setUpdateRatingsModal() {
      let clone = cloneDeep(this.row)
      Object.assign(clone.operator.affiliate_operators[0], this.inputRatings)
      this.$store.dispatch(`${this.storeName}/updateItemRating`, {
        index: this.index,
        item: this.inputRatings,
      })
    },
    async updateRatingsModal() {
      this.setUpdateRatingsModal()
      await this.updateRatings()
      this.close()
    },
    submitRatingsModal() {
      this.submitModal()
      this.close()
    },
  },
  watch: {
    inputRatings: {
      deep: true,
      handler(newVal) {
        Object.keys(this.initialRatings).map((key) => {
          if (newVal[key] > 5) {
            this.inputRatings[key] = this.initialRatings[key]
            this.showErrorMessages([`${key.split('_').join(' ').toUpperCase()} cannot exceed 5`])
          }
        })
      },
    },
  },
}
</script>

<style scoped>
.modal-dialog {
  max-width: 960px !important;
}
</style>
