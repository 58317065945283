import OperatorList from '@pages/dms/operators/operators.vue'
import Operator from '@pages/dms/operators/edit/edit.vue'
import OperatorHome from '@pages/dms/operators/edit/form.vue'
import OperatorMarketsData from '@pages/dms/operators/markets-data/markets-data.vue'
import MarketsGeneral from '@pages/dms/operators/markets-data/markets-general.vue'
import MarketCreate from '@pages/dms/operators/markets-data/create.vue'
import MarketEdit from '@pages/dms/operators/markets-data/edit.vue'
import OperatorWagering from '@pages/dms/operators/wagering/wagering.vue'
import OperatorSitesData from '@pages/dms/operators/sites-data/sites-data.vue'
import OperatorSitesDataGeneral from '@pages/dms/operators/sites-data/sites-data-general.vue'
import SitesDataCreate from '@pages/dms/operators/sites-data/create.vue'
import SitesDataEdit from '@pages/dms/operators/sites-data/edit.vue'
import SitesDataBonuses from '@pages/dms/operators/sites-data/bonuses.vue'
import SitesDataLinks from '@pages/dms/operators/sites-data/links.vue'
import OperatorToplists from '@pages/dms/operators/toplists.vue'

export default [
  {
    path: '/operators',
    component: OperatorList,
    name: 'Operators List',
    meta: {
      auth: true,
      parent: 'Data Management',
      permission: 'view.operators/operators',
      name: 'Operators List',
    },
  },
  {
    path: '/operators/:id',
    component: Operator,
    name: 'Rroni',
    children: [
      {
        path: '',
        component: OperatorHome,
        meta: {
          parent: 'Data Management',
          auth: true,
          permission: 'view.operators/operators',
          name: 'General',
        },
      },
      {
        path: 'toplists',
        name: 'OperatorToplists',
        component: OperatorToplists,
        meta: {
          parent: 'Data Management',
          name: 'Toplists',
          permission: 'view.toplists/lists',
        },
      },
      {
        path: 'wagering',
        name: 'OperatorWagering',
        component: OperatorWagering,
        meta: {
          parent: 'Data Management',
          name: 'Wagering',
          permission: 'view.operators/generic-bonuses',
        },
      },
      {
        path: 'markets-data',
        component: MarketsGeneral,
        meta: {
          parent: 'Data Management',
          name: 'Markets data',
          permission: 'view.operators/bonuses',
        },
        children: [
          {
            path: '',
            name: 'Markets data',
            component: OperatorMarketsData,
            meta: {
              parent: 'Data Management',
              permission: 'view.operators/bonuses',
            },
          },
          {
            path: 'create',
            name: 'Market Create',
            component: MarketCreate,
            meta: {
              parent: 'Data Management',
              name: 'Create Markets data',
              permission: 'view.operators/bonuses',
            },
          },
          {
            path: 'edit/:marketId',
            name: 'Market Edit',
            component: MarketEdit,
            meta: {
              parent: 'Data Management',
              name: 'Edit Markets data',
              permission: 'view.operators/bonuses',
            },
          },
        ],
      },
      {
        path: 'sites-data',
        component: OperatorSitesDataGeneral,
        meta: {
          parent: 'Data Management',
          name: 'Sites Data',
          permission: 'view.operators/affiliate-operators',
        },
        children: [
          {
            path: '',
            name: 'Sites Data',
            component: OperatorSitesData,
            meta: {
              parent: 'Data Management',
              permission: 'view.operators/affiliate-operators',
            },
          },
          {
            path: 'create',
            name: 'SitesDataCreate',
            component: SitesDataCreate,
            meta: {
              parent: 'Data Management',
              name: 'Create Sites data',
              permission: 'view.operators/affiliate-operators',
            },
          },
          {
            path: 'edit/:siteId',
            name: 'SitesDataEdit',
            component: SitesDataEdit,
            meta: {
              parent: 'Data Management',
              name: 'Edit Sites data',
              permission: 'view.operators/affiliate-operators',
            },
          },
          {
            path: 'bonuses/:bonusId',
            name: 'SitesDataBonuses',
            component: SitesDataBonuses,
            meta: {
              parent: 'Data Management',
              name: 'Edit Bonuses',
              permission: 'view.operators/affiliate-operators',
            },
          },
          {
            path: 'links/:linkId',
            name: 'SitesDataLinks',
            component: SitesDataLinks,
            meta: {
              parent: 'Data Management',
              name: 'Edit Links',
              permission: 'view.operators/affiliate-operators',
            },
          },
        ],
      },
    ],
    meta: {
      auth: true,
      parent: 'Data Management',
      name: 'Operators List',
    },
  },
]
