<template>
  <div
    class="modal"
    v-bind:class="{ show: isActive, 'modal-right': !isCentered }"
    ref="modal"
    tabindex="-1"
    role="dialog"
    v-click-outside="close"
    v-if="isActive"
    @click="clickOutside"
  >
    <div class="modal-dialog modal-lg2">
      <div class="modal-content" ref="modalContent">
        <div class="modal-header">
          <h5 class="modal-title" v-if="structure.config !== undefined">
            {{ $capitalize(formType) }} {{ structure.config.name }} {{ title ? ' - ' + title : '' }}
            <base-asset
              v-if="itemLogo != null && formType !== 'create'"
              :asset="itemLogo"
              width="50px"
              height="auto"
              class="p-0 rounded-circle border-none"
            />
          </h5>

          <div class="modal-title-buttons">
            <button @click="toggleModalPosition" class="modal-title-button">
              <i class="uil" v-bind:class="isCentered ? 'uil-arrow-to-right' : 'uil-focus'" />
            </button>

            <button class="close modal-title-button" @click="closeModalAfterSendingMessage"></button>
          </div>
        </div>
        <dynamic-form
          ref="form"
          :form-type="formType"
          v-if="object"
          :path="path"
          :inModal="true"
          :isCentered="isCentered"
          :formFields="structure.list"
          :filter="editFieldFilters"
          :object="object"
          :defaults="createDefaults"
          :extraFieldsObject="extraFieldsObject"
          :advancedRatingsObject="advancedRatingsObject"
          :twoColumnLayout="twoColumnLayout"
          :on-submit="submit"
          :on-remove="showRemove ? remove : () => {}"
          :extraFields="extraFields"
          :advancedRatings="advancedRatings"
          :show-remove="showRemove"
          :customCreateActionButton="customCreateActionButton"
          @notifyExtraFields="notifyExtraFields"
          @updateExtraFields="updateExtraFields"
          @updateAdvancedRatings="updateAdvancedRatings"
          @close-modal-after-message="closeModalAfterSendingMessage()"
          :pusherModel="pusherModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseAsset from '@atoms/fields/base-asset.vue'
import AssetsService from '@services/AssetsService.js'
import { loadService, loadModel } from '@mixins/HandleDynamicImports.js'

export default {
  components: {
    BaseAsset,
  },
  data() {
    return {
      formType: 'create',
      isCentered: this.centeredModal,
      structure: {},
      object: {},
      isActive: false,
      extraFields: [],
      advancedRatings: [],
      extraFieldsObject: {},
      advancedRatingsObject: {},
      config: {},
      isLoading: false,
      itemLogo: null,
    }
  },
  props: {
    checkStatusOfOperators: {
      type: Function,
      default: null,
    },
    path: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    serviceFile: {
      type: String,
      default: () => null,
    },
    serviceParams: {
      type: Array,
      default: () => [],
    },
    fieldFilter: {
      type: Array,
      default: () => [],
    },
    centeredModal: {
      default: () => true,
      type: Boolean,
    },
    createDefaults: {
      type: Object,
      default: () => {},
    },
    // fields that need to be removed from the object before submitting
    editFieldFilters: {
      type: Array,
      default: () => [],
    },
    submitDataModifier: {
      type: [Function],
      default: null,
    },
    showRemove: {
      type: Boolean,
      default: () => true,
    },
    twoColumnLayout: {
      default: () => false,
      type: Boolean,
    },
    notifyExtraFieldIdEdit: {
      type: String,
      default: () => null,
    },
    postCreateNavigationRoute: {
      type: String,
      default: () => null,
    },
    customCreateActionButton: {
      type: Object,
      default: () => {
        return {
          action: null,
          title: 'Primary',
          icon: 'uil uil-share-alt',
          roles: [],
        }
      },
    },
    notifyExtraFieldIdCreate: {
      type: String,
      default: () => null,
    },
    granular: {
      default: false,
      type: Boolean,
    },
    extraDeleteInfo: {
      type: Object,
      default: () => {
        return {
          title: 'Warning',
          message: '',
        }
      },
    },
    extraEditInfo: {
      type: Object,
      default: () => {
        return {
          title: 'Warning',
          message: '',
        }
      },
    },
    pusherModel: {
      type: String,
      default: () => {
        return 'default'
      },
    },
  },
  computed: {
    ...mapGetters('data-table', ['title', 'standardisedLogo']),
    ...mapState('dynamicForm', ['formObject', 'initFormObject', 'formSubmitted']),
    serviceLoader() {
      return () => loadService(this.serviceFile)
    },
    modelLoader() {
      return () => loadModel(this.model)
    },
    marketOperator() {
      return {
        market_id: this.object.market_id,
        operator_type: this.object.type,
        site_id: this.object.affiliate_id,
      }
    },
  },
  watch: {
    object: {
      deep: true,
      handler(newValue, oldValue) {
        this.$store.commit('dynamicForm/setFormObject', { ...newValue })
      },
    },
    marketOperator: {
      deep: true,
      handler(newVal, oldVal) {
        // Avoid calling the method on initial load
        if (
          oldVal &&
          (newVal.market_id !== oldVal.market_id ||
            newVal.operator_type !== oldVal.operator_type ||
            newVal.site_id !== oldVal.site_id) &&
          newVal.market_id !== null &&
          newVal.operator_type !== null &&
          newVal.site_id !== null
        ) {
          this.checkNotifyAdvancedRatings()
        }
      },
    },
  },
  async mounted() {
    if (this.model) {
      await this.modelLoader().then((model) => {
        if (model) {
          this.config = model.default.getConfig()
        }
      })
    }
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    async checkNotifyAdvancedRatings() {
      try {
        if (!this.model || !['AffiliateOperator', 'GameSiteData'].includes(this.model)) return

        const model = await this.modelLoader()

        this.advancedRatings = await model.default.getAdvancedRatingValues(
          this.object.id,
          this.object.affiliate_id,
          this.object.market_id
        )
        this.object.advanced_ratings = {}
        this.advancedRatingsObject = {}

        if (this.advancedRatings.length > 0) {
          this.advancedRatings.forEach((adv) => {
            this.advancedRatingsObject[adv.name] = adv.value
            this.object.advanced_ratings[adv.name] = adv.value
          })
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getAsset(value) {
      if (value === null) {
        return
      }
      await AssetsService.getOne(value, { with: 'site,market' }).then((response) => {
        if (response.data.result.length === 1) {
          return (this.itemLogo = response.data.result[0])
        } else {
          this.error = true
        }
      })
    },
    clickOutside(event) {
      if (event.target === this.$refs.modal && event.target !== this.$refs.modalContent) {
        this.handleClose()
      }
    },
    loadStructure(callback) {
      this.modelLoader().then((model) => {
        this.config = model.default.getConfig()
        this.structure = model.default.getStructure(...this.serviceParams)
        this.structure.list.forEach((field) => {
          if (field.type === 'model-select') {
            field.value_label = null
          }
        })
        if (callback) {
          return callback()
        }
      })
    },
    clear() {
      this.object = {}
      this.structure = {}
      this.extraFields = []
      this.extraFieldsObject = {}
      this.advancedRatingsObject = {}
      this.advancedRating = []
    },
    clearObjectState() {
      this.$store.commit('dynamicForm/setFormSubmitted', true)
      this.$store.commit('dynamicForm/setInitFormObject', {})
      this.$store.commit('dynamicForm/setFormObject', {})
    },
    handleClose() {
      if (
        !this.formSubmitted &&
        this.areObjectsDifferent(this.initFormObject, this.formObject, this.formType)
      ) {
        if (
          !confirm('You have unsaved changes. Please save or cancel the changes before closing the form.')
        ) {
          return
        }
      }
      this.close()
    },
    close() {
      this.clearObjectState()
      this.isActive = false
      this.clear()
    },
    closeModalAfterSendingMessage() {
      if (this.$refs && typeof this.$refs.modal !== 'undefined') {
        this.handleClose()
      }
    },
    toggleModalPosition() {
      this.isCentered = !this.isCentered
    },
    showModal() {
      this.loadStructure()
      this.getAsset(this.standardisedLogo)
      this.isActive = true
    },
    notifyExtraFields(method) {
      this.modelLoader().then(async (model) => {
        if (typeof model.default.getExtraFields !== 'undefined') {
          let needToWait = false
          if (method && Object.hasOwn(method, 'wait_for')) {
            method.wait_for.some((wait) => {
              if (
                !(
                  Object.hasOwn(this.object, wait) &&
                  typeof this.object[wait] !== 'undefined' &&
                  this.object[wait] !== null
                )
              ) {
                needToWait = true
                return true
              }
            })
          }

          if (!needToWait) {
            this.extraFields = await model.default.getExtraFields(this.object[method.param])
          } else {
            this.extraFields = []
          }

          if (this.extraFields.length > 0) {
            this.extraFields.forEach((extraField) => {
              this.extraFieldsObject[extraField.name] = null
            })
          }
        }
      })
    },
    updateExtraFields(values) {
      this.object.extra_fields = values
    },
    updateAdvancedRatings(values) {
      this.object.advanced_ratings = values
    },
  },
}
</script>
