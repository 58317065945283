<template>
  <div v-if="shouldShow" class="row mb-2">
    <div class="col"></div>
    <div class="col-auto">
      <div class="d-flex flex-column font-size-sm bg-orange-10 rounded-lg p-2">
        <span>
          <i class="uil uil-exclamation-triangle mr-1 text-orange"></i>
          {{ page.relation_warning }}
        </span>
        <span
          v-if="isPageRelationWarning"
          @click="shouldShowRelationModal"
          class="text-blue ml-4 cursor-pointer"
          >Click here to see where.</span
        >
      </div>
    </div>
  </div>
  <relation-modal :page="page" v-if="showRelationModal" ref="relationModal" />
</template>

<script>
import RelationModal from '../pages/relation-modal.vue'
export default {
  components: { RelationModal },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showRelationModal: false,
    }
  },
  computed: {
    shouldShow() {
      return this.page.template.enable_relation_warning && this.page.relation_warning
    },
    isPageRelationWarning() {
      return this.page?.related_page_links?.length > 0
    },
  },
  methods: {
    shouldShowRelationModal() {
      this.showRelationModal = true
      this.$nextTick(() => {
        this.$refs.relationModal.show()
      })
    },
  },
}
</script>
