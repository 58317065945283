<template>
  <Teleport to="body">
    <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add reply</h5>
            <button class="close" v-on:click="close"></button>
          </div>
          <div class="modal-body pb-2">
            <label for="comment" class="form-group">Comment</label>
            <textarea
              name="comment"
              :value="row?.comment"
              class="form-control mb-4"
              rows="5"
              disabled="true"
            ></textarea>
            <base-select
              label="Author"
              :clearable="false"
              v-model="replyFormObject.author"
              ref="providerSelect"
              :loadOnMount="false"
              :path="`hercules/sites/authors?site_id=${siteId}`"
              option-label-key="name"
              class="pr-2 mb-2"
            />
            <div>
              <base-textarea label="Comments" ref="input" :rows="3" v-model="replyFormObject.comment" />
            </div>
          </div>
          <div class="modal-footer pt-3 pr-4">
            <div class="w-25 text-right mr-3">
              <button type="submit" class="btn btn-primary" @click="submit" :class="{ loading: isLoading }">
                <i class="uil uil-check"></i>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { cloneDeep } from 'lodash'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import PageCommentService from '@services/PageCommentService.js'
import { reactive, onMounted, ref, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const modal = ref(null)
const isLoading = ref(false)
const emits = defineEmits(['replyCreated'])
const props = defineProps({
  siteId: {
    type: Number,
    default: () => null,
  },
  row: {
    type: Object,
    default: () => null,
  },
})

const replyFormObject = reactive({
  author: '',
  comment: '',
})

onMounted(() => {
  if (modal.value) {
    document.body.appendChild(modal.value)
  }
})

const show = () => {
  modal.value.classList.add('show')
}

const close = () => {
  modal.value?.classList.remove('show')
  replyFormObject.author = ''
  replyFormObject.comment = ''
}

const submit = async () => {
  isLoading.value = true
  try {
    const payload = cloneDeep(props.row)
    payload.comment = replyFormObject.comment
    payload.author_id = replyFormObject.author
    payload.parent_id = props.row.id

    const response = await PageCommentService.post(payload)
    if (response.data.messages) {
      proxy.showErrorMessage(response.data.messages[0])
    } else {
      proxy.showSuccessMessage('Reply added')
      close()
      emits('replyCreated')
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

defineExpose({
  show,
})
</script>
<style scoped>
.modal-dialog {
  max-width: 960px !important;
}
.gap-4 {
  gap: 16px;
}
.modal-footer {
  padding: 0;
  margin-bottom: 16px;
}
</style>
