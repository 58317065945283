<template>
  <div>
    <PageDescriptions
      description="Configure which modules should be shown on every page in a section of this page type."
    />
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex flex-column">
          <h3>Sections</h3>
        </div>
        <div v-if="showPreview">
          <base-checkbox
            label="Preview modules"
            v-model="previewMode"
            ref="pastEventsSelect"
            :switch="true"
            class="pr-3 pl-3 mb-0"
          />
          <div class="position-absolute right-0 mr-4 animate fn-flash=new" v-if="previewMode">
            <div class="callout callout-danger">
              This is <strong>not</strong> an accurate representation, always check the page on the real site!
            </div>
          </div>
        </div>
        <div class="ml-auto d-flex">
          <div v-if="confluenceId">
            <span @click="openModalInformation()" class="btn btn-outline-info btn-md mr-2">
              <i class="uil uil-info-circle"></i>Help
            </span>
            <div @click="viewActivityLogs()" class="btn btn-outline-info btn-md popover-trigger">
              <i class="uil uil-history"></i>History
            </div>
          </div>
          <div v-if="showSave">
            <button
              class="btn btn-primary mr-2 ml-3"
              @click="$emit('save')"
              :disabled="showColaborativeNotification || loading"
              v-bind:class="{ loading: loading }"
            >
              <i class="uil uil-checkk"></i>
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-2" style="min-height: 400px">
        <div class="card-body pb-0" v-if="sections && sections.length === 0">
          There are no sections enabled.
        </div>
        <ul class="nav nav-tabs mb-3" v-if="activeIndex !== null">
          <li
            v-for="(section, index) in sections"
            v-bind:class="{ active: activeIndex === index }"
            v-bind:key="section.id"
          >
            <a @click="sectionTabClick(index)">{{ section.name }}</a>
          </li>
        </ul>
        <modules
          v-if="activeIndex !== null && !previewMode"
          ref="editModule"
          :moduleOwnerType="moduleOwnerType"
          :moduleOwnerId="moduleOwnerId"
          @moduleAdded="(module) => $emit('moduleAdded', module)"
          :siteId="siteId"
          :affiliateId="affiliateId"
          :marketId="marketId"
          :section="activeSection"
          :showPreview="showPreview"
          @previewModule="previewModule"
          @changeSection="changeSection"
          :pageTitle="pageTitle"
        />
        <modules-preview
          v-if="showPreview && activeIndex !== null && previewMode"
          ref="previewModule"
          :moduleOwnerType="moduleOwnerType"
          :moduleOwnerId="moduleOwnerId"
          :siteId="siteId"
          :affiliateId="affiliateId"
          :marketId="marketId"
          :section="activeSection"
          @editModule="editModule"
        />
        <information-modal ref="informationModal" :id="confluenceId" />
      </div>
    </div>
  </div>
</template>
<script>
import ModulesPreview from '@templates/cms/modules/modules-preview.vue'
import Modules from '@templates/cms/modules/modules.vue'
import ColorsService from '@services/ColorsService.js'
import StyleService from '@services/ModuleStylesService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import patterns from '@constants/regex-patterns.js'
import PageDescriptions from '@/components/PageDescriptions.vue'

export default {
  data() {
    return {
      activeIndex: null,
      previewMode: false,
      confluenceId: 0,
    }
  },
  computed: {
    activeSection() {
      return this.sections[this.activeIndex]
    },
  },
  created() {
    this.assignToConfluenceId()
  },
  mounted() {
    if (this.sections && this.sections.length > 0) {
      let defaultIndex = 0
      const hash = window.location.hash
      if (hash && hash.includes('?')) {
        const queryString = hash.split('?')[1]
        const params = new URLSearchParams(queryString)
        const sectionIdParam = params.get('section_id')
        if (sectionIdParam) {
          const foundIndex = this.sections.findIndex((section) => section.id.toString() === sectionIdParam)
          if (foundIndex !== -1) {
            defaultIndex = foundIndex
          }
        }
      }
      this.activeIndex = defaultIndex
      const sectionId = this.sections[defaultIndex].id
      window.history.replaceState(window.history.state, '', `#sections?section_id=${sectionId}`)
    }
    ColorsService.get({ site_id: this.siteId, orderBy: 'label', ascending: 1 })
      .then((response) => {
        if (response.data.success) {
          this.$store.dispatch('cms/setColors', response.data.result)
        }
      })
      .catch(this.showUnknownErrorMessage)

    StyleService.get({ site_id: this.siteId, orderBy: 'name', ascending: 1 })
      .then((response) => {
        if (response.data.success) {
          if (response.data.result.length > 0) {
            this.$store.dispatch('cms/setStyles', response.data.result)
          }
        }
      })
      .catch(this.showUnknownErrorMessage)

    if (this.$route.query['index']) {
      this.editModule(this.$route.query['index'])
    }
  },
  methods: {
    changeSection(value) {
      const section = this.sections[this.activeIndex]
      section.style ? (section.style.section_style_id = value) : (section.style = { section_style_id: value })
    },
    assignToConfluenceId() {
      if (this.tooltip) {
        this.confluenceId = this.tooltip
      } else {
        let match = this.$route.path.match(patterns.sections.regex)
        if (match && match.length > 0 && match[0] === this.$route.path) {
          this.confluenceId = patterns.sections.id
        }
      }
    },
    previewModule(index) {
      this.previewMode = true
      setTimeout(() => {
        this.$scrollTo(`#section-module-preview-${index}`, 100, { lazy: false, offset: -100 })
      }, 500)
    },
    editModule(index) {
      this.previewMode = false
      this.$nextTick(() => {
        const module = this.activeSection?.module_values?.[index]
        if (module) {
          module.visible = true
        } else {
          console.warn('No module found.')
        }

        this.$nextTick(() => {
          this.$scrollTo(`#section-module-${index}`, 200, { lazy: false, offset: -100 })
        })
      })
    },
    sectionTabClick(index) {
      this.activeIndex = null
      this.$nextTick(() => {
        this.activeIndex = index
        const sectionId = this.sections[index].id
        window.history.replaceState(window.history.state, '', `#sections?section_id=${sectionId}`)
      })
    },
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    viewActivityLogs() {
      let routeData = this.$router.resolve({
        path: `/activity-logs?`,
        query: {
          subject_name: this.moduleOwnerId,
          fromComponent: 'section',
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  components: {
    Modules,
    ModulesPreview,
    BaseCheckbox,
    InformationModal,
    PageDescriptions,
  },
  props: {
    sections: {
      type: Array,
    },
    showSave: {
      type: Boolean,
      default: () => false,
    },
    showPreview: {
      type: Boolean,
      default: () => false,
    },
    moduleOwnerType: {
      type: String,
      required: true,
    },
    siteId: {
      required: true,
    },
    loading: {},
    affiliateId: {},
    marketId: {},
    moduleOwnerId: {
      required: true,
    },
    tooltip: {
      type: String,
    },
    showColaborativeNotification: {
      type: Boolean,
    },
    siteMarket: {},
    pageTitle: {
      type: String,
      default: () => '',
    },
  },
  // beforeUnmount() {

  // },
}
</script>
