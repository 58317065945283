<template>
  <div>
    <span class="badge bg-black-25 mb-1 mr-1" v-for="(label, index) in labelsToShow" v-bind:key="index">
      <!-- regex to match only text -->
      {{ isSingle ? label.replace(/[\[\]"]+/g, '') : label.split('|')[2].replace(/[\[\]"]+/g, '') }}
    </span>
    <span v-if="!isSingle">
      <span v-if="labelCount > 0" class="popover-wrapper" data-popover-position="bottom">
        <span v-if="labelCount > 2" class="popover-trigger badge bg-blue-25"
          >Show all ({{ labelCount }})</span
        >
        <span class="popover-content" style="max-width: inherit;">
          <div class="popover-header">List of {{ getType }}</div>
          <table class="table">
            <thead>
              <tr>
                <th>{{ getType === 'Events' ? 'Date' : 'Gender' }}</th>
                <th>{{ getType === 'Events' ? 'Type' : 'Sport' }}</th>
                <th>Name</th>
                <th>{{ getType === 'Events' ? 'Tournament' : 'Country' }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(label, index) in sanitizedLabels" v-bind:key="index">
                <td class="text-nowrap" v-for="(cell, index) in label.split('|')" v-bind:key="index">
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </span>
      </span>
      <span v-else class="popover-trigger badge bg-red-10">No {{ getType }} selected</span>
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    getLabels() {
      return this.labels.split('","')
    },
    labelCount() {
      return this.getLabels ? this.getLabels.length : 0
    },
    labelsToShow() {
      return this.getLabels.filter((item, index) => {
        return index < this.limit && item
      })
    },
    sanitizedLabels() {
      return this.getLabels.map((item) => {
        return item.replace('[', '').replace(']', '').replaceAll('"', '')
      })
    },
    getType() {
      return this.type.toLowerCase().includes('event') ? 'Events' : 'Tournaments'
    },
    isSingle() {
      return ['event_details', 'tournament_standings', 'round_schedule', 'Tournament', 'tournament'].includes(this.type)
    }
  },
  props: {
    labels: {
      type: String,
      default: () => ''
    },
    limit: {
      type: Number,
      default: () => 3
    },
    type: {
      type: String,
      default: () => ''
    }
  }
}
</script>
