<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <div class="border py-3">
      <div class="border border-light mx-3 mb-3" v-for="(record, key) in localRecords" :key="key">
        <div class="m-3">
          <div class="text-muted">{{ label }} entry #{{ key }}</div>
          <div class="d-flex" style="gap: 16px">
            <div class="d-flex flex-grow-1 mt-2">
              <base-number-input
                v-model="record.rating"
                :step="0.1"
                type="number"
                label="Rating"
                helpText="Add a rating for this critic"
              />
            </div>
            <div class="d-flex flex-grow-1 mt-2">
              <BaseSelect
                class="w-100 ml-1"
                :customOptions="getFilteredCritics(record.critic_id)"
                optionLabelKey="name"
                v-model="record.critic_id"
                label="Critics"
                helpText="Please select a critic"
              />
            </div>
          </div>
          <a class="btn btn-secondary btn-sm h-50 align-self-start mt-4" @click="deleteRow(key)">
            <i class="uil uil-trash-alt mr-2"></i>Delete entry #{{ key }}
          </a>
        </div>
      </div>
      <div class="d-flex">
        <a
          class="btn btn-primary btn-sm ml-3"
          :class="{
            'disabled-link': localRecords.length >= critics.length,
          }"
          @click="addRow"
        >
          <i class="uil uil-plus-alt mr-2"></i>+ Add {{ localRecords.length === 0 ? 'a' : 'another' }} critic
          rating
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import { cloneDeep, isEqual } from 'lodash'
import BaseNumberInput from '@atoms/fields/base-number-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import CriticsService from '@services/CriticsService'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])
const critics = ref([])
const localRecords = ref(cloneDeep(props.modelValue))

const getCriticsFromService = async () => {
  try {
    await CriticsService.get().then((response) => (critics.value = response.data.result))
  } catch (error) {
    console.error(error, 'An error occurred during the fetch')
  }
}

const getFilteredCritics = (selectedCriticId) => {
  const selectedIds = localRecords.value.map((rec) => rec.critic_id).filter((id) => !!id)
  return critics.value.filter((critic) => critic.id === selectedCriticId || !selectedIds.includes(critic.id))
}

watch(
  () => props.modelValue,
  (newVal) => {
    localRecords.value = cloneDeep(newVal)
  },
  { deep: true, immediate: true }
)

watch(
  localRecords,
  (newVal) => {
    if (!isEqual(newVal, props.modelValue)) {
      emit('update:modelValue', newVal)
    }
  },
  { deep: true }
)

const deleteRow = (index) => {
  localRecords.value = localRecords.value.filter((_record, key) => key !== index)
}

const addRow = (event) => {
  if (localRecords.value.length >= critics.value.length) {
    event.preventDefault()
    return
  }

  localRecords.value.push({
    rating: null,
    critic_id: null,
  })
}

onMounted(async () => {
  await getCriticsFromService()
  // Adding 1 row by default if no rows found
  if (localRecords.value.length === 0) {
    localRecords.value.push({
      rating: null,
      critic_id: null,
    })
  }
})
</script>
<style scoped>
.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  color: #fff !important;
  background-color: lightgray !important;
}
</style>
