<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div class="card" v-else>
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure?.list?.filter((field) => !field.hidden)"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
      :on-remove="remove"
      :extraFields="extraFields"
      :extra-item-title="operator.name"
      :extraFieldsObject="extraFieldsObject"
      :topExampleFields="topExampleFields"
      :topExampleFieldValues="topExampleFieldValues"
      :advancedRatingsObject="advancedRatingsObject"
      :advancedRatings="advancedRatings"
      @notifyExtraFields="notifyExtraFields"
      @updateExtraFields="updateExtraFields"
      @updateAdvancedRatings="updateAdvancedRatings"
    />
  </div>
</template>

<script setup>
import { ref, reactive, watch, getCurrentInstance, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AffiliateOperator from '@models/AffiliateOperator.js'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

const object = ref({})
const affiliate_id = ref(null)
const structure = reactive({ list: [] })
const config = AffiliateOperator.getConfig()
const sitesDataId = ref(null)
const isLoading = ref(true)
const form = ref(null)

const store = useStore()
const operator = computed(() => ({ ...store.state.editPage.model }))

const route = useRoute()
const router = useRouter()
const extraFieldsObject = ref({})
const extraFields = ref([])
const advancedRatingsObject = ref({})
const advancedRatings = ref([])

const topExampleFields = AffiliateOperator.getTopExampleFields()
const topExampleFieldValues = ref([])

const { proxy } = getCurrentInstance()

const getSitesData = async () => {
  isLoading.value = true
  try {
    const response = await AffiliateOperatorsService.getOne(sitesDataId.value, {
      with: 'affiliate,market,selling_points,asset_details,ribbons,currencies,restricted_countries,pros,cons',
    })

    if (response) {
      affiliate_id.value = response.data.result[0].affiliate_id
      object.value = response.data.result[0]
      getTopExampleFieldValues()

      const visibilityRules = await getFieldsVisibility()
      const list = AffiliateOperator.getStructure(route?.params?.id).list

      // Safely map visibility rules for robust matching
      const visibilityRuleNames = visibilityRules.map((item) => ({
        fieldName: item.field?.name?.toLowerCase() || '',
        displayName: item.field?.display_name?.toLowerCase() || '',
      }))

      structure.list = list.map((field) => {
        const fieldNameLower = field.name?.toLowerCase() || ''
        const displayNameLower = field.display_name?.toLowerCase() || ''

        // Check if the field should be hidden based on visibility rules
        const isFieldHiddenByRules = visibilityRuleNames.some(
          (rule) => rule.fieldName === fieldNameLower || rule.displayName === displayNameLower
        )

        // Special handling for the 'Logo' field
        const isLogoField =
          field.display_name === 'Logo' &&
          visibilityRuleNames.some((rule) => rule.fieldName === 'logo' || rule.displayName === 'logo')

        // Special handling for the 'Animation' field
        const isAnimationField =
          field.display_name === 'Animation' &&
          visibilityRuleNames.some(
            (rule) => rule.fieldName === 'animation' || rule.displayName === 'animation'
          )

        // Apply hiding logic
        // if (isLogoField || isFieldHiddenByRules) {
        //   if (field.type === 'list-many') {
        //     field.hideListMany = true
        //   } else {
        //     field.hidden = true
        //   }
        //   return field
        // }

        // Handle inputs for fields that are not hidden
        if (field.inputs && field.inputs.length > 0) {
          const filteredInputs = field.inputs.filter(
            (inputName) => !visibilityRuleNames.some((rule) => rule.fieldName === inputName.toLowerCase())
          )

          field.inputs = filteredInputs

          if (field.inputs.length === 0) {
            field.hidden = true
          }
        }

        return field
      })
    }
  } catch (error) {
    console.error('Error fetching site data:', error)
  } finally {
    isLoading.value = false
  }
}

const getFieldsVisibility = async () => {
  if (affiliate_id.value) {
    try {
      const response = await AffiliateOperatorsService.getVisibleFields(affiliate_id.value, 'Site Data')
      return response.data.result || []
    } catch (error) {
      console.error('Error fetching fields visibility:', error)
      return []
    }
  }
}

const submit = async () => {
  if (!form.value) {
    console.error('Form is not initialized')
    proxy.showUnknownErrorMessage()
    return
  }

  form.value.isLoading = true

  try {
    object.value = {
      ...object.value,
      ...topExampleFieldValues.value,
    }

    const response = await form.value.process(object.value)
    const updateResponse = await AffiliateOperatorsService.update(response)

    if (object.value.advanced_ratings) {
      object.value.advanced_ratings = {}
    }

    if (updateResponse.data.success && updateResponse.data.status === 1) {
      const entityName = structure?.config?.name || 'Sites data'
      proxy.showSuccessMessage(`${entityName} updated`)
    } else if (updateResponse.data.messages) {
      proxy.showErrorMessages(updateResponse.data.messages)
    } else {
      proxy.showUnknownErrorMessage()
    }
  } catch (error) {
    console.log('Error during submission:', error)
    proxy.showUnknownErrorMessage()
  } finally {
    form.value.isLoading = false
  }
}

const remove = async () => {
  if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
    try {
      const response = await AffiliateOperatorsService.remove({
        id: sitesDataId.value,
      })

      if (response.data.messages) {
        proxy.showErrorMessages(response.data.messages)
      } else if (response.data.success) {
        const name = structure?.config?.name || 'Item'
        proxy.showSuccessMessage(`${name} removed`)
        router.push(`/operators/${route.params.id}/sites-data`)
      } else {
        proxy.showErrorMessages(['Unexpected response from server'])
      }
    } catch (error) {
      console.error('Error in remove:', error)
      proxy.showUnknownErrorMessage(error)
    }
  }
}

const getTopExampleFieldValues = () => {
  if (!object.value) return

  const fields = []
  fields.example_top_game = object.value.example_top_game
  fields.example_top_table_game = object.value.example_top_table_game
  fields.example_top_jackpot_game = object.value.example_top_jackpot_game
  fields.example_top_slot_game = object.value.example_top_slot_game
  fields.example_top_live_game = object.value.example_top_live_game
  fields.example_top_software = object.value.example_top_software
  fields.example_top_live_software_provider = object.value.example_top_live_software_provider

  topExampleFieldValues.value = fields
}

const notifyExtraFields = async () => {
  // only notify extra fields (clear fields that are notified)
  extraFields.value = await AffiliateOperator.getExtraFields(route.params.siteId)
  if (extraFields.value.length > 0) {
    extraFields.value.forEach((extraField) => {
      extraFieldsObject.value[extraField.name] = null
    })
  }
}

const getExtraFieldsValues = async () => {
  extraFields.value = await AffiliateOperator.getExtraFieldsValues(route.params.siteId)
  if (extraFields.value.length > 0) {
    extraFields.value.forEach((extraField) => {
      extraFieldsObject.value[extraField.name] = extraField.value
    })
  }
}

const getAdvancedRatingValues = async () => {
  advancedRatings.value = await AffiliateOperator.getAdvancedRatingValues(
    route.params.siteId,
    object.value.affiliate_id,
    object.value.market_id,
    object.value.type
  )

  object.value.advanced_ratings = {}
  advancedRatingsObject.value = {}

  if (advancedRatings.value.length > 0) {
    advancedRatings.value.forEach((advancedRatings) => {
      advancedRatingsObject.value[advancedRatings.name] = advancedRatings.value
      object.value.advanced_ratings[advancedRatings.name] = advancedRatings.value
    })
  }
}

onMounted(async () => {
  await getExtraFieldsValues()
})

const updateExtraFields = (values) => {
  object.value.extra_fields = values
}

const updateAdvancedRatings = (values) => {
  object.value.advanced_ratings = { ...values }
}

watch(
  () => route.params.siteId,
  (newVal) => {
    if (newVal) {
      object.value = {}
      sitesDataId.value = newVal
      getSitesData()
      getFieldsVisibility()
    }
  },
  { immediate: true }
)

watch(
  [() => object.value.market_id, () => object.value.type],
  ([newMarketId, newType], [oldMarketId, oldType]) => {
    if (newMarketId !== oldMarketId || newType !== oldType) {
      getAdvancedRatingValues(route.params.siteId, object.value.market_id, object.value.type)
    }
  },
  { immediate: true }
)
</script>
