<template>
  <div>
    <data-table
      model="ProsCons"
      ref="list"
      service-file="ProsConsService"
      :path="path"
      :columns="columns()"
      :createDefaults="{ active: true }"
      :searchDelay="200"
      :rowActionButtons="rowActions()"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useTitle } from '@/composables/useTitle.js'
import TableColumnTemplate from '@constants/table-column-template.js'
import { useRouter } from 'vue-router'

const { setTitle } = useTitle()
const router = useRouter()

const list = ref(null)
const path = 'hercules/globals/pros-cons?orderBy=updated_at&ascending=0'

const columns = () => {
  return [
    {
      field: 'type',
      label: 'Type',
      type: 'text',
      filterable: true,
    },
    {
      field: 'name',
      label: 'Name',
      type: 'text',
      filterable: true,
    },
    {
      field: 'updated_at',
      type: 'text',
      template: TableColumnTemplate.RELATIVE_DATE,
      filterable: false,
    },
  ]
}

const rowActions = () => {
  return [
    {
      action: translationAction,
      title: 'Translations',
      icon: 'uil uil-adjust',
      class: 'btn-info',
    },
  ]
}

const translationAction = (row) => {
  let routeData = router.resolve('translations?key=' + row.short_name)
  window.open(routeData.href, '_blank')
}

onMounted(() => {
  setTitle()
})
</script>
