<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" id="toplist-add-modal" v-click-outside="close">
    <div class="modal-dialog modal-lg2">
      <div class="modal-content">
        <div class="modal-header pb-3 d-flex flex-column position-relative">
          <div class="d-flex align-items-center w-100">
            <h5 class="modal-title">Other pages that use same relation</h5>
            <button class="close" @click="close"></button>
          </div>
        </div>

        <div class="modal-body">
          <div class="pt-1" v-for="(link, index) in page.related_page_links" :key="index">
            <span class="d-block">
              <a :href="link.link" target="blank">
                {{ link.title }}
              </a>
            </span>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show() {
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
