<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :customOptions="marketOptions"
          optionLabelKey="market_label"
          v-model="market"
          label="Market"
          :loadOnMount="false"
          trackBy="market_label"
          placeholder="Select a market"
          selectLabel="market_label"
          :countryFlag="countryFlag"
          :clearable="true"
          :searchBy="'market_label'"
          @update:modelValue="onFilterChange"
        />
      </div>
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :customOptions="typeOptions"
          :loadOnMount="false"
          :clearable="true"
          optionLabelKey="type"
          trackBy="type"
          :searchBy="'type'"
          v-model="type"
          label="Type"
          @update:modelValue="onFilterChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, watch, onMounted } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import GeneralService from '@/services/GeneralService.js'

const store = useStore()
const route = useRoute()
const isMarketPreSelected = computed(() => store.state.editPage.marketId)
const isTypePreSelected = computed(() => store.state.editPage.operatorType)
const market = ref(isMarketPreSelected.value)
const marketOptions = ref([])
const typeOptions = ref([])
const dataForMarketAndType = ref([])
const type = ref(isTypePreSelected.value)
const emit = defineEmits(['filterChange'])

const countryFlag = computed(() => {
  const selectedMarket = marketOptions.value.find((option) => option.market_label === market.value)
  return selectedMarket ? selectedMarket.countryFlag : null
})

const operatorId = computed(() => {
  return route.params.id
})

const onFilterChange = () => {
  emit('filterChange', market.value, type.value)
  store.commit('editPage/setMarketId', market.value || null)
  store.commit('editPage/setOperatorType', type.value || null)
}

watch([market, type], () => {
  onFilterChange()
})

onMounted(() => {
  getMarketAndTypeOptions()
})

const getMarketAndTypeOptions = async () => {
  const path = `hercules/operators/bonuses?with=market&operator_id=${operatorId.value}`
  try {
    const response = await GeneralService.fetchItems(path, {})
    dataForMarketAndType.value = response.data.result
    // Process data to get unique options
    processUniqueOptions()
  } catch (error) {
    console.error(error)
  }
}

const processUniqueOptions = () => {
  marketOptions.value = getUniqueOptions(dataForMarketAndType.value, 'market_label').map((item) => ({
    market_label: item.market_label,
    countryFlag: item.market.country_code,
  }))

  typeOptions.value = getUniqueOptions(dataForMarketAndType.value, 'type').map((item) => ({
    type: item.type,
  }))
}

const getUniqueOptions = (data, key) => {
  const uniqueValues = new Map()
  return data.filter((item) => {
    const value = item[key]
    if (!uniqueValues.has(value)) {
      uniqueValues.set(value, true)
      return true
    }
    return false
  })
}
</script>
<style scoped>
.rounded-md {
  border-radius: 8px;
}
</style>
