import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/templates/${id}`, {
      params: data,
    })
  },
  get(data) {
    return Api().get(`hercules/sites/templates`, {
      params: data,
    })
  },
  post(data) {
    return Api().post(`hercules/sites/templates`, data)
  },
  update(data) {
    const payload = { ...data }

    if (
      payload.enable_advanced_page_paths_format == 0 ||
      payload.enable_advanced_page_paths_format == false
    ) {
      payload.page_path_format = null
    }
    return Api().put(`hercules/sites/templates/${payload.id}`, payload)
  },
  remove(row) {
    return Api().delete(`hercules/sites/templates/${row.id}`)
  },
  compare(data) {
    return Api().get(`hercules/sites/templates/compare`, {
      params: data,
    })
  },
}
