// import Reports from '@pages/cms/reports/reports.vue'
import Redirects from '@pages/cms/redirects/redirects.vue'

import TemplateBlocks from '@pages/cms/template/blocks/blocks.vue'
import Template from '@pages/cms/template/template.vue'
import TemplateExtraFields from '@pages/cms/template/extra-fields/extra-fields.vue'
import TemplateStyles from '@pages/cms/template/styles/styles.vue'
import TemplateEdit from '@pages/cms/template/edit/edit.vue'
import TemplateForm from '@pages/cms/template/edit/form.vue'
import TemplateSections from '@pages/cms/template/sections/sections.vue'
import TemplateSeo from '@pages/cms/template/seo/seo.vue'
import TemplateAutogeneratedContent from '@pages/cms/template/autogenerated-content/autogenerated-content.vue'

import Authors from '@pages/cms/authors/authors.vue'
import AuthorEdit from '@pages/cms/authors/edit/edit.vue'
import AuthorGeneral from '@pages/cms/authors/edit/general.vue'
import AuthorPages from '@pages/cms/authors/edit/pages.vue'
import ReviewerPages from '@pages/cms/authors/edit/reviewer-pages.vue'

import Banners from '@pages/cms/banners/banners.vue'
import BannerEdit from '@pages/cms/banners/edit/edit.vue'
import BannerGeneral from '@pages/cms/banners/edit/general.vue'
import BannerItems from '@pages/cms/banners/edit/items.vue'

import Menus from '@pages/cms/menus/menus.vue'
import MenuEdit from '@pages/cms/menus/edit/edit.vue'
import MenuGeneral from '@pages/cms/menus/edit/general.vue'
import MenuItems from '@pages/cms/menus/edit/items.vue'

import Sites from '@pages/cms/sites/sites.vue'
import SiteHome from '@pages/cms/sites/home.vue'
import SiteEdit from '@pages/cms/sites/edit.vue'
import SiteMarkets from '@pages/cms/sites/markets.vue'
import SiteCategories from '@pages/cms/sites/categories.vue'
import SiteSchema from '@pages/cms/sites/schema.vue'
import SiteModuleStyles from '@pages/cms/sites/module-styles.vue'
import SiteColors from '@pages/cms/sites/colors.vue'
import SiteBreadcrumbs from '@pages/cms/sites/breadcrumbs.vue'

import PageEdit from '@pages/cms/pages/edit/edit.vue'
import Pages from '@pages/cms/pages/pages.vue'
import Comments from '../views/UserContent/Comments.vue'
import SiteDeployments from '@pages/devops/site-deployments.vue'

import MarketSections from '@pages/templates/market-sections/market-sections.vue'
import MarketSectionsEdit from '@pages/templates/market-sections/market-sections-edit.vue'
import EditSectionModules from '@templates/cms/section/section.vue'
import PrefilledModuleEdit from '@pages/templates/prefilled-modules/prefilled-module-edit.vue'
import PrefilledModules from '@pages/templates/prefilled-modules/prefilled-modules.vue'
import PrefilledModuleEditGeneral from '@pages/templates/prefilled-modules/prefilled-module-edit-general.vue'
import PrefilledModuleEditMarkets from '@pages/templates/prefilled-modules/prefilled-module-edit-markets.vue'

import SectionEdit from '@pages/templates/sections/section-edit.vue'
import SectionExtraFields from '@pages/templates/sections/section-extra-fields.vue'
import SectionStyles from '@pages/templates/sections/section-styles.vue'
import SectionEditGeneral from '@pages/templates/sections/section-edit-general.vue'
import Section from '@pages/templates/sections/section.vue'
import ModuleEdit from '@pages/templates/modules/module-edit.vue'
import Modules from '@pages/templates/modules/modules.vue'

export default [
  {
    path: '/site-pages',
    component: Pages,
    name: 'Pages',
    meta: {
      auth: true,
      parent: 'Site Management',
      permission: 'view.sites/pages',
      name: 'Pages',
    },
  },
  {
    path: '/site-pages/:id',
    name: 'site-pages-edit',
    component: PageEdit,
    meta: {
      auth: true,
      parent: 'Site Management',
      permission: 'view.sites/pages',
      name: 'Pages',
    },
    children: [
      {
        path: '',
        component: PageEdit,
        name: 'site-pages-edit-general',
        meta: {
          parent: 'Site Management',
          auth: true,
          permission: 'view.sites/pages',
          name: 'General',
        },
      },
    ],
  },
  {
    path: '/site-sites',
    component: Sites,
    name: 'Sites Settings',
    meta: {
      auth: true,
      parent: 'Site Management',
      permission: 'view.sites/sites',
      name: 'Sites Settings',
    },
  },
  {
    path: '/site-sites/:id',
    component: SiteHome,
    name: 'Sites Setting',
    meta: {
      auth: true,
      parent: 'Site Management',
      permission: 'view.sites/sites',
      name: 'Sites Settings',
    },
    children: [
      {
        path: '',
        component: SiteEdit,
        meta: {
          parent: 'Site Management',
          auth: true,
          permission: 'view.sites/sites',
          name: 'General',
        },
      },
      {
        path: 'markets',
        component: SiteMarkets,
        meta: { parent: 'Site Management', name: 'Markets', permission: 'view.sites/site-markets' },
      },
      {
        path: 'colors',
        component: SiteColors,
        meta: { parent: 'Site Management', name: 'Module Colors', permission: 'view.sites/colors' },
      },
      {
        path: 'module-styles',
        component: SiteModuleStyles,
        meta: { parent: 'Site Management', name: 'Module Styles', permission: 'view.sites/styles' },
      },
      {
        path: 'schema',
        component: SiteSchema,
        meta: { parent: 'Site Management', name: 'Schema', permission: 'view.sites/schema-information' },
      },
      {
        path: 'categories',
        component: SiteCategories,
        meta: { parent: 'Site Management', name: 'Categories', permission: 'view.sites/categories' },
      },
      {
        path: 'breadcrumbs',
        component: SiteBreadcrumbs,
        meta: { parent: 'Site Management', name: 'Breadcrumbs', permission: 'view.sites/breadcrumbs' },
      },
    ],
  },
  {
    path: '/site-menus',
    component: Menus,
    name: 'Menus',
    meta: {
      auth: true,
      parent: 'Site Management',
      permission: 'view.sites/menus',
      name: 'Menus',
    },
  },
  {
    path: '/site-menus/:id',
    component: MenuEdit,
    children: [
      {
        path: '',
        component: MenuGeneral,
        meta: {
          parent: 'Site Management',
          auth: true,
          name: 'General',
        },
      },
      {
        path: 'items',
        component: MenuItems,
        meta: { parent: 'Site Management', name: 'Items' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Menus',
      permission: 'view.sites/menus',
    },
  },
  {
    path: '/site-banners',
    component: Banners,
    name: 'Banners',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Banners',
    },
  },
  {
    path: '/site-banners/:id',
    component: BannerGeneral,
    children: [
      {
        path: '',
        component: BannerEdit,
        meta: {
          parent: 'Site Management',
          auth: true,
          name: 'General',
        },
      },
      {
        path: 'items',
        component: BannerItems,
        meta: { parent: 'Site Management', name: 'Banner Items' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Banners',
    },
  },
  {
    path: '/site-authors',
    component: Authors,
    name: 'Authors',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Authors',
    },
  },
  {
    path: '/site-authors/:id',
    component: AuthorGeneral,
    children: [
      {
        path: '',
        component: AuthorEdit,
        meta: {
          parent: 'Site Management',
          auth: true,
          name: 'General',
        },
      },
      {
        path: 'pages',
        component: AuthorPages,
        meta: { parent: 'Site Management', name: 'Author Pages' },
      },
      {
        path: 'reviewer-pages',
        component: ReviewerPages,
        meta: { parent: 'Site Management', name: 'Reviewer Pages' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Authors',
    },
  },
  {
    path: '/site-redirects',
    component: Redirects,
    name: 'Redirects',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Redirects',
      permission: 'view.sites/redirects',
    },
  },
  {
    path: '/schema',
    component: SiteSchema,
    meta: { parent: 'Site Management', name: 'Schema', permission: 'view.sites/schema-information' },
  },
  {
    path: '/breadcrumbs',
    component: SiteBreadcrumbs,
    name: 'Breadcrumbs',
    meta: { parent: 'Site Management', name: 'Breadcrumbs', permission: 'view.sites/breadcrumbs' },
  },
  {
    path: '/site-templates',
    component: Template,
    name: 'Page Type',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Page Type',
      permission: 'view.sites/templates',
    },
  },
  {
    path: '/site-templates/:id',
    component: TemplateEdit,
    children: [
      {
        path: '',
        component: TemplateForm,
        meta: {
          parent: 'Site Management',
          auth: true,
          permission: 'view.sites/templates',
          name: 'General',
        },
      },
      {
        path: '/site-templates/:id/blocks',
        component: TemplateBlocks,
        meta: {
          parent: 'Site Management',
          name: 'Template Blocks',
          auth: true,
          permission: 'view.sites/templates',
        },
      },
      {
        path: '/site-templates/:id/sections',
        component: TemplateSections,
        meta: { parent: 'Site Management', name: 'Sections', auth: true, permission: 'view.sites/templates' },
      },
      {
        path: '/site-templates/:id/seo',
        component: TemplateSeo,
        meta: {
          parent: 'Site Management',
          name: 'Default SEO',
          auth: true,
          permission: 'view.sites/templates',
        },
      },
      {
        path: '/site-templates/:id/page-styles',
        component: TemplateStyles,
        meta: { parent: 'Site Management', name: 'Styles', permission: 'view.sites/templates' },
      },
      {
        path: '/site-templates/:id/extra-fields',
        component: TemplateExtraFields,
        meta: {
          parent: 'Site Management',
          name: 'Extra Fields',
          auth: true,
          permission: 'view.sites/templates',
        },
      },
      {
        path: '/site-templates/:id/autogenerated-content',
        component: TemplateAutogeneratedContent,
        meta: {
          parent: 'Site Management',
          name: 'Autogenerated Tab',
          auth: true,
          permission: 'view.sites/templates',
        },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Page Type',
    },
  },
  // {
  //   path: '/reports',
  //   component: Reports,
  //   meta: {
  //     auth: true,
  //     parent: 'Site Management',
  //     name: 'Reports'
  //   }
  // },
  {
    path: '/user-comments',
    component: Comments,
    name: 'User Comments',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'User Comments',
    },
  },
  {
    path: '/site-deployments',
    component: SiteDeployments,
    name: 'Site Deployments',
    meta: {
      parent: 'Site Management',
      auth: true,
      name: 'Site Deployments',
    },
  },
  // Templates
  {
    path: '/site-markets',
    component: MarketSections,
    name: 'Market Sections',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Market Sections',
      permission: 'view.sites/site-markets',
    },
  },
  {
    path: '/site-markets/:id',
    component: MarketSectionsEdit,
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Market Sections',
      permission: 'view.sites/site-markets',
    },
    children: [
      {
        path: '',
        component: EditSectionModules,
        meta: {
          parent: 'Site Management',
          auth: true,
          permission: 'view.sites/site-markets',
          name: 'Market Section',
        },
      },
    ],
  },
  {
    path: '/site-prefilled-modules',
    component: PrefilledModules,
    name: 'Prefilled Modules',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Prefilled Modules',
      permission: 'view.sites/prefilled-modules',
    },
  },
  {
    path: '/site-prefilled-modules/:id',
    component: PrefilledModuleEdit,
    children: [
      {
        path: '',
        component: PrefilledModuleEditGeneral,
        meta: {
          auth: true,
          name: 'General',
          permission: 'view.sites/prefilled-modules',
          parent: 'Site Management',
        },
      },
      {
        path: '/site-prefilled-modules/:id/markets',
        component: PrefilledModuleEditMarkets,
        meta: {
          auth: true,
          name: 'Markets',
          permission: 'view.sites/prefilled-modules',
          parent: 'Site Management',
        },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Prefilled Modules',
      permission: 'view.sites/prefilled-modules',
    },
  },
  {
    path: '/site-sections',
    component: Section,
    name: 'Sections',
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Sections',
      permission: 'view.sites/sections',
    },
  },
  {
    path: '/site-sections/:id',
    component: SectionEdit,
    children: [
      {
        path: '',
        component: SectionEditGeneral,
        meta: {
          auth: true,
          name: 'General',
          permission: 'view.sites/sections',
          parent: 'Site Management',
        },
      },
      {
        path: '/site-sections/:id/extra-fields',
        component: SectionExtraFields,
        meta: { parent: 'Site Management', name: 'Section Extra Fields', permission: 'view.sites/sections' },
      },
      {
        path: '/site-sections/:id/styles',
        component: SectionStyles,
        meta: { parent: 'Site Management', name: 'Section Styles', permission: 'view.sites/section-styles' },
      },
    ],
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Sections',
    },
  },
  {
    path: '/site-modules',
    component: Modules,
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Modules',
      permission: 'view.sites/modules',
    },
  },
  {
    path: '/site-modules/:id',
    component: ModuleEdit,
    meta: {
      auth: true,
      parent: 'Site Management',
      name: 'Site Module Edit',
      permission: 'view.sites/modules',
    },
  },
]
