<template>
  <div :class="wrapperClass">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>

    <div class="dropdown" v-if="options.length > 0">
      <span @click="clearSelection" v-if="canBeCleared">
        <i class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0" style="top: 5px" />
      </span>
      <div class="multiselect__arrow" :class="{ active: showDropdown }" v-else></div>
      <input
        type="text"
        :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
        @focus="dropdownClicked"
        @click="dropdownClicked"
        class="form-control"
        v-model="activeOptionLabel"
        :placeholder="placeholderLabel ? placeholderLabel : placeholder"
        ref="dropdownInput"
        @blur="hideDropdown()"
        @keydown.up.exact.prevent="focusPrevious(true)"
        @keydown.down.exact.prevent="focusNext(true)"
        @keypress.enter.exact.prevent="selectItem()"
        @keyup.esc.exact.prevent="$parent.formType === 'edit' && hideDropdown()"
      />
      <label class="form-text text-danger" v-if="showBottomLabel">
        &nbsp;*&nbsp;You must select an account before submitting.
      </label>
      <ul
        v-if="showDropdown"
        class="dropdown-menu"
        :class="{ show: showDropdown }"
        ref="dropdown-list"
        :style="[
          staticStyles,
          dropdownHeight !== null ? { 'max-height': dropdownHeight + 'px', 'overflow-y': 'auto' } : {},
        ]"
      >
        <li v-if="loading">Loading ...</li>
        <li @click="clearSelection" v-if="showAllOption && optionItems.length">All</li>
        <li
          v-for="(option, key) in optionItems"
          :key="key"
          :value="option instanceof Object ? option[trackBy] : option"
          :class="[
            {
              active:
                (option instanceof Object ? data === option[trackBy] : data === option) && !removeActiveText,
              'active-item':
                (option instanceof Object ? pointer === option[trackBy] : pointer === option) &&
                (option instanceof Object ? data !== option[trackBy] : data !== option) &&
                allowNullOption &&
                !removeActiveText,
              'active-item-remove':
                (option instanceof Object ? pointer === option[trackBy] : pointer === option) &&
                (option instanceof Object ? data === option[trackBy] : data === option) &&
                allowNullOption &&
                !removeActiveText,
              disabled: option instanceof Object ? option.disabled : false,
            },
            option instanceof Object ? option.customClass : '',
          ]"
          class="list-item text-truncate"
          @click="onChange(option)"
          @mouseover="changePointer(option[optionLabelKey] || option, key)"
        >
          {{ prettifyLabels(Object.hasOwn(option, optionLabelKey) ? option[optionLabelKey] : option) }}
          <span class="text-muted ml-1" v-if="optionItems.length > 0 && option.pages_count">
            ({{ option.pages_count }})
          </span>
          <span
            v-if="option instanceof Object ? data === option[trackBy] : data === option"
            class="float-right"
          >
            {{
              (option instanceof Object ? pointer !== option[trackBy] : pointer !== option) &&
              allowNullOption &&
              !removeActiveText
                ? 'Selected'
                : ''
            }}
          </span>
        </li>
        <li v-if="optionItems.length === 0 && !loading">No matching records</li>
      </ul>
    </div>

    <div v-else class="form-control text-muted">
      <template v-if="loading">
        <span>Loading ...</span>
      </template>
      <template v-else>
        <span>No {{ noOptionsPlaceholder }} available</span>
        <span v-if="noOptionsLink">
          , click
          <a :href="noOptionsLink" target="blank"> here </a>
          to add one
        </span>
      </template>
    </div>

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  name: 'CustomOptionsSelect',
  props: {
    showBottomLabel: {
      type: Boolean,
      default: false,
    },
    removeActiveText: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      default: () => 'form-group position-relative',
    },
    capitalizeLabel: {
      default: true,
      type: Boolean,
    },
    clearableAbsolutePositioned: {
      default: () => false,
    },
    clearable: {
      default: () => false,
    },
    clearableIfOneOption: {
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    modelValue: {
      default: () => null,
    },
    customOptions: {
      default: () => null,
    },
    readOnly: {
      default: () => false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    emitOption: {
      type: String,
      default: () => null,
    },
    noOptionsPlaceholder: {
      type: String,
      default: 'options',
    },
    noOptionsLink: {
      type: String,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    showAllOption: {
      type: Boolean,
      default: false,
    },
    notifiedByField: {
      type: Object,
      default: () => null,
    },
    allowNullOption: {
      type: Boolean,
      default: false,
    },
    pageCount: {
      type: Boolean,
      default: false,
    },
    enableDynamicHeight: {
      type: Boolean,
      default: false,
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'list-one-simple',
    },
  },
  data() {
    return {
      data: this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue,
      options: [],
      loading: true,
      optionItems: [],
      activeOptionLabel: '',
      placeholderLabel: '',
      pointer: 0,
      focusedIndex: 0,
      activeOption: 0,
      showDropdown: false,
      dynamicHeight: null,
      staticStyles: {
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 9998,
      },
    }
  },
  computed: {
    dropdownHeight() {
      // 52 is the height of the "All" option (if displayed)
      return this.dynamicHeight > 0 && this.enableDynamicHeight ? this.dynamicHeight - 52 : null
    },
    canBeCleared() {
      if (!this.clearable || !this.data) return false
      if (this.options.length === 1) return this.clearableIfOneOption
      return true
    },
  },
  created() {
    if (this.customOptions) {
      this.options = [...this.customOptions]
      this.optionItems = [...this.customOptions]
      this.updateActiveOptionLabel()
      this.loading = false
    }
    window.addEventListener('click', (e) => {
      if (this.$el.querySelector('.dropdown') && !this.$el.querySelector('.dropdown').contains(e.target)) {
        this.showDropdown = false
      }
    })

    if (this.modelValue instanceof Object) {
      const selectedItem =
        this.options.find((item) => item[this.trackBy] === this.modelValue[this.trackBy]) || {}
      this.$emit('update:modelValue', this.data, this.emitOption ? selectedItem[this.emitOption] : '')
    }
  },
  watch: {
    modelValue() {
      this.updateActiveOptionLabel()
    },
    customOptions(newVal) {
      if (newVal) {
        this.options = [...newVal]
        this.optionItems = [...newVal]
        this.updateActiveOptionLabel()
        this.loading = false
      }
    },
    options() {
      // Whenever the options array changes, reset the optionItems list.
      this.optionItems = [...this.options]
      if (this.optionItems.length > 0) {
        if (this.pointer !== (this.optionItems[0] && this.optionItems[0][this.trackBy])) {
          this.focusItem()
        }
      }
    },
    activeOptionLabel(newVal) {
      // Basic filtering: filter the available options based on the input text.
      const searchLower = newVal ? newVal.toLowerCase() : ''
      this.optionItems = this.options.filter((item) => {
        const optionLabel =
          item instanceof Object && this.optionLabelKey in item
            ? item[this.optionLabelKey].toString().toLowerCase()
            : item.toString().toLowerCase()
        return optionLabel.includes(searchLower)
      })
      // Optionally, sort the filtered list
      this.optionItems.sort((a, b) => {
        const getOptionLabel = (item) =>
          item instanceof Object && this.optionLabelKey in item
            ? item[this.optionLabelKey].toString().toLowerCase()
            : item.toString().toLowerCase()
        return getOptionLabel(a).localeCompare(getOptionLabel(b))
      })
    },
  },
  methods: {
    updateActiveOptionLabel() {
      if (this.data !== null && this.data !== undefined && this.options.length > 0) {
        let option = this.options.find((item) =>
          item instanceof Object
            ? item[this.trackBy] === (!isNaN(this.data) ? parseInt(this.data) : this.data)
            : item === this.data
        )
        let optionLabel = option instanceof Object ? option[this.optionLabelKey] : option
        this.activeOptionLabel = this.capitalizeLabel ? this.capitalize(optionLabel) : optionLabel
      } else {
        this.activeOptionLabel = ''
        this.placeholderLabel = ''
      }
    },
    dropdownClicked() {
      const now = Date.now()
      if (now - this.lastDropdownClicked < 250) return
      this.lastDropdownClicked = now
      this.placeholderLabel = this.activeOptionLabel
      this.activeOptionLabel = ''
      this.$refs.dropdownInput?.focus()
      this.showDropdown = true
      this.getDropdownHeight()
      if (!this.pointer) {
        this.focusItem()
      }
    },
    getDropdownHeight() {
      this.$nextTick(() => {
        const dropdownListRect = this.$refs['dropdown-list'].getBoundingClientRect()
        const tableRect = document.querySelector('.card-body')
          ? document.querySelector('.card-body').getBoundingClientRect()
          : 0
        const spaceBelowViewport = window.innerHeight - dropdownListRect.top
        const spaceBelowTable = tableRect.bottom - dropdownListRect.top
        const maxDropdownHeight = Math.min(spaceBelowViewport, spaceBelowTable)
        this.dynamicHeight = maxDropdownHeight > 0 ? maxDropdownHeight : 0
      })
    },
    onChange(option) {
      if ((option instanceof Object ? option[this.trackBy] : option) === this.data && this.allowNullOption) {
        this.data = null
        this.activeOptionLabel = ''
      } else {
        this.data = option instanceof Object ? option[this.trackBy] : option
      }
      const selectedItem =
        this.options.find(
          (item) => item[this.trackBy] === (option instanceof Object ? option[this.trackBy] : option)
        ) || {}
      this.$emit('update:modelValue', this.data, this.emitOption ? selectedItem[this.emitOption] : '')
      // Reset the options list to show all customOptions after selection.
      this.optionItems = [...this.customOptions]
      this.showDropdown = false
      this.$refs.dropdownInput.blur()
    },
    focusNext(isArrowKey) {
      this.focusedIndex = (this.focusedIndex + 1) % this.optionItems.length
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = (this.focusedIndex - 1 + this.optionItems.length) % this.optionItems.length
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem() {
      if (this.optionItems.length > 0) {
        this.pointer = this.optionItems[this.focusedIndex]?.[this.trackBy] || null
      } else {
        this.pointer = null
      }
    },
    selectItem() {
      this.onChange(this.optionItems[this.focusedIndex])
    },
    changePointer(element, index) {
      this.focusedIndex = index
      this.focusItem()
    },
    clearSelection() {
      this.$emit('update:modelValue', null)
      this.data = null
    },
    hideDropdown() {
      setTimeout(() => {
        this.$refs.dropdownInput?.blur()
        this.showDropdown = false
        this.focusedIndex = 0
        // Reset the optionItems to the full list.
        this.optionItems = [...this.options]
        this.focusItem()
      }, 300)
    },
    prettifyLabels(label) {
      return this.capitalizeLabel && label ? label.charAt(0).toUpperCase() + label.slice(1) : label
    },
  },
}
</script>

<style scoped>
.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.dropdown input {
  padding: 0 14px;
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.active-item {
  background-color: #f6f6fb;
}

.dropdown > input.form-control {
  background-image: unset !important;
}

.dropdown > input.form-control::placeholder,
.dropdown > input.form-control:focus {
  color: #999 !important;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.absolute-clear-button {
  position: absolute;
  top: -25px;
  right: 4px;
}

.country-flag {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
</style>
<style lang="scss">
.optionTooltip {
  position: absolute;
  z-index: 9999;
  width: max-content;
  padding: 8px 16px;
  border-radius: 4px;
}
</style>
