import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/globals/critics`, {
      params: data,
    })
  },
  getOne(id) {
    return Api().get(`hercules/globals/critics/${id}`)
  },
  post(data) {
    return Api().post(`hercules/globals/critics`, data)
  },
  update(data) {
    return Api().put(`hercules/globals/critics/${data.id}`, data)
  },
  remove(data) {
    return Api().delete(`hercules/globals/critics/${data.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/globals/countries/critics`)
  },
}
