<template>
  <data-table
    ref="table"
    model="PageComments"
    service-file="PageCommentService"
    :path="`hercules/content/comments?reference_id=${route.params.id}`"
    :columns="columns()"
    :serviceParams="[siteId]"
    :column-templates="columnTemplates()"
    :createDefaults="defaultCommentObject"
    :showEditModal="false"
    :customRowClick="commentsEditModal"
  />
  <CommentsEditModal ref="modalRef" :row="selectedRow" :siteId="siteId" @replyChanged="refreshTable" />
  <AddReplyModal ref="replyModalRef" :siteId="siteId" :row="selectedRow" @replyCreated="refreshTable" />
</template>
<script setup lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import RepliesCount from '@atoms/table/cell/replies-count.vue'
import CommentsEditModal from './comments/comments-edit-modal.vue'
import { reactive, ref } from 'vue'
import AddReplyModal from './comments/add-reply-modal.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps({
  siteId: {
    type: Number,
    required: true,
  },
  affiliateSiteId: {
    type: Number,
    required: true,
  },
  marketShortcode: {
    type: String,
    required: true,
  },
  pageId: {
    type: Number,
    required: true,
  },
})

const table = ref(null)
const defaultCommentObject = reactive({
  site_id: props.affiliateSiteId,
  market_short_code: props.marketShortcode,
  reference_id: props.pageId,
})

// Edit Modal related logic
const selectedRow = ref(null)
const modalRef = ref(null)
const replyModalRef = ref(null)

const openReplyCreateModal = (rowId) => {
  selectedRow.value = rowId
  replyModalRef.value.show()
}

const openModal = () => {
  modalRef.value.show()
}

const commentsEditModal = (event, row) => {
  selectedRow.value = row
  openModal()
}

const columns = () => {
  return [
    { field: 'name', type: 'text', filterable: true },
    { field: 'email', type: 'text', filterable: true },
    { field: 'replies_count', type: 'text', label: 'Replys', filterable: true },
    // { field: 'approved', type: 'checkbox', filterable: false, template: TableColumnTemplate.BOOLEAN },
    { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
    { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
  ]
}

const columnTemplates = () => {
  return {
    reference_name: function (row) {
      if (row.reference_name) {
        return row.reference_name
      }
      return row.reference_id
    },
    replies_count: function (row) {
      return (
        <RepliesCount replies={row.replies_count} onCreateRepliesModal={() => openReplyCreateModal(row)} />
      )
    },
  }
}

const refreshTable = () => {
  if (table.value && table.value.search) {
    table.value.search()
  }
}
</script>
