import Api from './Api'

export default {
  getOne(commentId) {
    return Api().get('hercules/content/comments/' + commentId)
  },
  post(data) {
    return Api().post('hercules/content/comments', data)
  },
  update(data) {
    return Api().put('hercules/content/comments/' + data.id, data)
  },
  remove(data) {
    return Api().delete('hercules/content/comments/' + data.id)
  },
}
