export default Object.freeze({
  BOOLEAN: 'boolean',
  DATE: 'date',
  PRETTY_LABELS: 'pretty_labels',
  DATE_TIME: 'date_time',
  RELATIVE_DATE: 'relative_date',
  FORMATTED_DATE_TIME: 'formatted_date_time',
  STATUS: 'status',
  OPERATOR_STATUS: 'operator_status',
  MAIN_LINK: 'main_link',
  LOGS_STATUS: 'logs_status',
  ACCOUNT_STATUS: 'account_status',
  IMAGE: 'image',
  TOPLIST: 'toplist',
  BADGES: 'badges',
  CLIPBOARD: 'clipboard',
  LINK_CELL: 'link_cell',
  LINK_FOR_REDIRECTS: 'link_for_redirects',
  ODDS_STATUS: 'odds_status',
  SPORTS_STATUS: 'sports_status',
  EVENT_STATUS: 'event_status',
  COLOR: 'color',
  TEXT_TRUNCATE: 'truncate',
  TEXT_TRUNCATE_TOOLTIP: 'truncate_tooltip',
  LIST_FROM_ARRAY: 'list_array',
  MARKET_LABEL: 'market_label',
})
