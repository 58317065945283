import BasicOptions from './BasicOptions'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  getParams: function (model, multipleSelect, value, withValue) {
    let params = {}

    if (value || withValue) {
      let key = 'id'
      if (model === 'provider') key = 'provider_id'

      if (multipleSelect) {
        key = 'ids'
      }
      params[key] = value
    }

    if (model === 'operator') {
      params.with = 'operator,market'
      params.match_market_language = 1
    } else if (model === 'page') {
      params.with = 'template,market,site_market'
      params.match_market_language = 1
    } else if (model === 'menu') {
      params.with = 'market'
    } else if (model === 'banner') {
      params.with = 'market'
    } else if (model === 'event') {
      params.with = 'event.sport'
    } else if (model === 'events' || model === 'livegoal_event') {
      params.with = 'sport,tournament,tournament.country'
    } else if (model === 'top_list') {
      params.with = 'market'
      params.match_market_language = 1
    } else if (model === 'tournament') {
      params.with = 'sport,country'
      params.exact_match = 'gender'
    } else if (model === 'provider') {
      params.with = 'provider'
      params.orderBy = 'enabled'
    } else if (model === 'team') {
      params.with = 'country,sport'
    } else if (model === 'page_path') {
      params.site_market = 1
    } else if (model === 'game') {
      params.with = 'game'
    }

    return params
  },
  getValueKey(model, row) {
    switch (model) {
      case 'provider':
        return row.provider_id
      default:
        return row.id
    }
  },
  getValueKeyName: function (model) {
    switch (model) {
      case 'operator':
        return 'operator.name'
      case 'event':
        return 'event.name'
      case 'page_path':
        return 'page_path'
      case 'events':
        return ['date', 'sport.name', 'name', 'tournament.name', 'status']
      case 'top_list':
        return 'label'
      case 'provider':
        return 'provider.label'
      case 'page':
      case 'menu':
      case 'odds':
      case 'sports':
        return 'title'
      case 'game':
        return 'game.name'
      default:
        return 'name'
    }
  },
  getModelPath: function (model) {
    switch (model) {
      case 'operator':
        return 'hercules/operators/affiliate-operators'
      case 'banner':
        return 'hercules/sites/banners'
      case 'provider':
        return 'hercules/sports/site-providers'
      case 'team':
        return 'hercules/sports/teams'
      case 'tournament':
        return 'hercules/sports/tournaments'
      case 'sport':
        return 'hercules/sports/sports'
      case 'events':
        return 'hercules/sports/events'
      case 'event':
        return 'hercules/sports/site-events'
      case 'livegoal_event':
        return 'hercules/sports/events'
      case 'menu':
        return 'hercules/sites/menus'
      case 'page':
        return 'hercules/sites/pages'
      case 'top_list':
        return 'hercules/toplists/lists'
      case 'game_category':
        return 'hercules/globals/game-categories'
      case 'payment_method':
        return 'hercules/globals/deposit-methods'
      case 'software_provider':
        return 'hercules/globals/software'
      case 'game':
        return 'hercules/games/affiliate-games'
      case 'author':
        return 'hercules/sites/authors'
      case 'gambling_company':
        return 'hercules/operators/gambling-companies'
      case 'odds':
        return 'hercules/widgets/odds'
      case 'affiliate_program':
        return 'hercules/operators/affiliate-programs'
      case 'page_path':
        return 'hercules/sites/pages'
      case 'crypto_brokers':
        return 'hercules/cryptos/brokers'
      case 'crypto_exchanges':
        return 'hercules/cryptos/exchanges'
      case 'crypto_wallets':
        return 'hercules/cryptos/wallets'
      case 'globals_competition':
        return 'hercules/globals/sport-competitions'
      case 'operators_sport_category':
        return 'hercules/operators/sport-categories'
      case 'operators_betting_type':
        return 'hercules/operators/betting-types'
      case 'operators_license':
        return 'hercules/operators/licenses'
    }
  },
  getModelColumns(model) {
    let columns = []
    if (model === 'operator') {
      columns.push({ field: 'operator.name', type: 'text', filterable: true, label: 'Name' })
      columns.push({ field: 'market.label', type: 'text', filterable: true, label: 'Market' })
      columns.push({ field: 'type', type: 'text', filterable: true })
    } else if (model === 'top_list') {
      columns.push({ field: 'label', type: 'text', filterable: true })
      columns.push({ field: 'short_code', type: 'text', filterable: true })
      columns.push({ field: 'operator_type', type: 'text', filterable: true })
      columns.push({
        field: 'market.label',
        search_key: 'market_name',
        type: 'text',
        label: 'Market',
        filterable: true,
      })
      columns.push({ field: 'items_count', type: 'text', filterable: true, label: 'Item Count' })
    } else if (model === 'page') {
      columns.push({ field: 'title', type: 'text', filterable: true })
      columns.push({ field: 'path', type: 'text', filterable: true })
      columns.push({ field: 'market.label', type: 'text', label: 'Market', filterable: false })
      columns.push({ field: 'template', type: 'text', label: 'Template', filterable: true })
    } else if (model === 'menu') {
      columns.push({ field: 'title', type: 'text', filterable: true })
      columns.push({ field: 'market.label', type: 'text', label: 'Market', filterable: false })
    } else if (model === 'banner') {
      columns.push({ field: 'name', type: 'text', filterable: true })
      columns.push({ field: 'short_name', type: 'text', filterable: true })
      columns.push({ field: 'orientation', type: 'text', filterable: true })
      columns.push({ field: 'market.label', type: 'text', label: 'Market', filterable: false })
    } else if (model === 'provider') {
      columns.push({ field: 'provider.label', type: 'text', label: 'Name', filterable: true })
      columns.push({ field: 'enabled', type: 'text', filterable: true })
    } else if (model === 'tournament') {
      columns.push({
        field: 'sport.name',
        type: 'text',
        filterable: true,
        label: 'Sport',
        order_key: 'sport__name',
        search_key: 'sport__name',
        advancedFilter: false,
        path: 'hercules/sports/sports?active=1',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
      })
      columns.push({
        field: 'gender',
        type: 'text',
        filterable: true,
        advancedFilter: false,
        clearable: true,
        allowNullOption: true,
        customOptions: [
          { label: 'All', id: null },
          { label: 'Male', id: 'male' },
          { label: 'Female', id: 'female' },
        ],
        basicFilterType: 'select',
        optionLabelKey: 'label',
        exact_match: 1,
      })
      columns.push({
        field: 'name',
        type: 'text',
        filterable: true,
        advancedFilter: false,
        path: 'hercules/sports/tournaments',
        search_key: 'name',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
        advancedFilterParams: true,
      })
      columns.push({
        field: 'country.name',
        type: 'text',
        filterable: true,
        label: 'Country',
        search_key: 'country__name',
        order_key: 'country__name',
        advancedFilter: false,
        path: 'hercules/sports/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
      })
    } else if (model === 'team') {
      columns.push({ field: 'name', type: 'text', filterable: true })
      columns.push({
        field: 'sport.name',
        filterable: true,
        type: 'text',
        label: 'Sport',
        order_key: 'sport__name',
        search: 'sport__name',
      })
      columns.push({
        field: 'country.name',
        filterable: true,
        type: 'text',
        label: 'Country',
        search_key: 'country__name',
        order_key: 'country__name',
      })
      columns.push({ field: 'gender', type: 'text', sortable: false })
    } else if (model === 'events' || model === 'livegoal_event') {
      columns.push({
        field: 'name',
        type: 'text',
        label: 'Name',
        filterable: true,
        advancedFilter: false,
        path: 'hercules/sports/events?hide_past_events=1&orderBy=name&ascending=1',
        search_key: 'name',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
      })
      columns.push({
        field: 'sport.name',
        search_key: 'sport__name',
        order_key: 'sport__name',
        type: 'text',
        label: 'Sport',
        filterable: true,
        advancedFilter: false,
        path: 'hercules/sports/sports?active=1',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
      })
      columns.push({
        field: 'tournament.gender',
        search_key: 'tournament__gender',
        order_key: 'tournament__gender',
        type: 'text',
        label: 'Gender',
        filterable: true,
        advancedFilter: false,
        clearable: true,
        allowNullOption: true,
        customOptions: [
          { label: 'All', id: null },
          { label: 'Male', id: 'male' },
          { label: 'Female', id: 'female' },
        ],
        basicFilterType: 'select',
        optionLabelKey: 'label',
        exact_match: 1,
      })
      columns.push({
        field: 'tournament.name',
        search_key: 'tournament__name',
        order_key: 'tournament__name',
        type: 'text',
        label: 'Tournament',
        filterable: true,
        advancedFilter: false,
        path: 'hercules/sports/tournaments',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
        enableTooltip: true,
        advancedFilterParams: true,
      })
      columns.push({
        field: 'tournament.country.name',
        search_key: 'tournament__country__name',
        order_key: 'tournament__country__name',
        type: 'text',
        label: 'Country',
        filterable: true,
        advancedFilter: false,
        path: 'hercules/sports/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        optionLabelKey: 'name',
        basicFilterType: 'select',
        sortable: true,
        showEmptyNull: false,
        fetch_on_load: false,
        trackBy: 'name',
        showAllOption: true,
      })
      columns.push({
        field: 'date',
        search_key: 'date',
        order_key: 'date',
        type: 'text',
        label: 'Date',
        filterable: true,
        basicFilterType: 'date',
        dateOptions: ['only_future_dates'],
      })
      columns.push({
        field: 'time',
        search_key: 'time',
        order_key: 'time',
        type: 'text',
        label: 'Time',
        filterable: true,
      })
      columns.push({
        field: 'status',
        search_key: 'status',
        type: 'text',
        filterable: true,
        template: TableColumnTemplate.EVENT_STATUS,
        advancedFilter: false,
        showAllOption: true,
        allowNullOption: true,
        customOptions: [
          { id: '1st half' },
          { id: '1st Set' },
          { id: '2nd half' },
          { id: '2nd Set' },
          { id: '3rd Set' },
          { id: 'Abandoned' },
          { id: 'Awaiting info' },
          { id: 'Cancelled' },
          { id: 'Extra Time - 2nd half' },
          { id: 'Finished' },
          { id: 'Finished AET' },
          { id: 'Finished after awarded win' },
          { id: 'Finished AP' },
          { id: 'Halftime' },
          { id: 'Interrupted' },
          { id: 'Kick Off Delayed' },
          { id: 'Live' },
          { id: 'Not started' },
          { id: 'Penalty' },
          { id: 'Postponed' },
          { id: 'To Finish' },
          { id: 'Waiting for extra time' },
        ],
        basicFilterType: 'select',
        optionLabelKey: 'id',
        exact_match: 1,
      })
    } else if (model === 'event') {
      columns.push({
        field: 'event.name',
        search_key: 'event__name',
        order_key: 'event__name',
        type: 'text',
        label: 'Name',
        filterable: true,
      })
      columns.push({
        field: 'event.sport.name',
        search_key: 'event__sport__name',
        order_key: 'event__sport__name',
        type: 'text',
        label: 'Sport',
        filterable: true,
      })
      columns.push({
        field: 'event.date',
        search_key: 'event__date',
        order_key: 'event__date',
        type: 'text',
        label: 'Sport date',
        filterable: true,
      })
    } else if (model === 'gambling_company') {
      columns.push({ field: 'name', type: 'text', filterable: true, label: 'Name' })
      columns.push({ field: 'short_name', type: 'text', filterable: true, label: 'Short Name' })
    } else if (model === 'odds') {
      columns.push({ field: 'title', type: 'text', filterable: true, label: 'Title' })
      columns.push({ field: 'odds_type.name', type: 'text', filterable: true, label: 'Type' })
    } else if (model === 'affiliate_program') {
      columns.push({ field: 'name', type: 'text', filterable: true, label: 'Name' })
      columns.push({ field: 'short_name', type: 'text', filterable: true, label: 'Short Name' })
    } else if (model === 'page_path') {
      columns.push({ field: 'title', type: 'text', filterable: true, label: 'Title' })
      columns.push({ field: 'type', type: 'text', filterable: true, label: 'Type' })
      columns.push({ field: 'path', type: 'text', filterable: true, label: 'Path' })
      columns.push({
        field: 'market.label',
        type: 'text',
        filterable: true,
        label: 'Market',
        search_key: 'market',
        order_key: 'market',
      })
      columns.push({
        field: 'site_market.path_prefix',
        type: 'text',
        filterable: true,
        label: 'Path_prefix',
        search_key: 'path_prefix',
        sortable: false,
      })
    } else if (model === 'game') {
      columns.push({
        field: 'game.name',
        type: 'text',
        filterable: true,
        label: 'Name',
        search_key: 'name',
      })
    } else if (model === 'crypto_brokers') {
      columns.push({ field: 'short_name', type: 'text', filterable: true })
      columns.push({ field: 'name', type: 'text', filterable: true })
      columns.push({ field: 'url', type: 'text', filterable: true })
    } else if (model === 'crypto_exchanges') {
      columns.push({ field: 'short_name', type: 'text', filterable: true })
      columns.push({ field: 'name', type: 'text', filterable: true })
      columns.push({ field: 'url', type: 'text', filterable: true })
    } else if (model === 'crypto_wallets') {
      columns.push({ field: 'short_name', type: 'text', filterable: true })
      columns.push({ field: 'name', type: 'text', filterable: true })
      columns.push({ field: 'url', type: 'text', filterable: true })
    } else {
      columns.push({ field: 'name', type: 'text', filterable: true })
    }

    if (['team', 'tournament', 'events'].includes(model) === false) {
      columns.push({
        field: 'updated_at',
        type: 'text',
        filterable: true,
        template: TableColumnTemplate.RELATIVE_DATE,
        basicFilterType: 'date',
        dateOptions: ['only_past_dates'],
      })
    }
    return columns
  },
  getModelColumnTemplates(model) {
    let columnTemplates = Object.assign(
      BasicOptions.relativeDateTemplate(['updated_at']),
      BasicOptions.activeTemplate(['enabled'])
    )

    if (model === 'page') {
      columnTemplates = Object.assign(columnTemplates, {
        template: function (row) {
          return row.template ? row.template.name : ''
        },
        title: function (row) {
          return row.title.substring(0, 30) + (row.title.length > 34 ? '...' : '')
        },
      })
    }
    if (model === 'event') {
      columnTemplates = Object.assign(columnTemplates, {
        'event.sport.name': function (row) {
          return this.capitalize(row.event.sport.name)
        },
        'event.name': function (row) {
          return this.capitalize(row.event.name)
        },
        'event.date': function (row) {
          return this.capitalize(row.event.date)
        },
      })
    }

    if (model === 'tournament') {
      columnTemplates = Object.assign(columnTemplates, {
        'sport.name': function (row) {
          return this.capitalize(row.sport.name)
        },
        'country.name': function (row) {
          return this.capitalize(row.country.name)
        },
      })
    }

    if (model === 'events' || model === 'livegoal_event') {
      columnTemplates = Object.assign(columnTemplates, {
        'sport.name': function (row) {
          return this.capitalize(row.sport.name)
        },
        'tournament.name': function (row) {
          return this.capitalize(row.tournament.name)
        },
      })
    }

    if (model === 'operator') {
      columnTemplates = Object.assign(columnTemplates, {
        'operator.name': function (row) {
          return this.capitalize(row.operator.name)
        },
      })
    }

    if (['top_list', 'menu', 'banner'].includes(model) === true) {
      columnTemplates = Object.assign(columnTemplates, {
        'market.label': function (row) {
          return this.capitalize(row.market.label)
        },
      })
    }
    return columnTemplates
  },
}
