<template>
  <div class="d-flex gap-4">
    <span class="replies-count" :class="[replies === 0 && 'replies-zero']"> {{ replies }} </span>
    <button class="btn btn-secondary btn-sm d-flex align-items-center" @click.stop="openCreateRepliesModal">
      <i class="uil uil-plus m-0 mb-1" />
    </button>
  </div>
</template>

<script setup>
const emits = defineEmits(['createRepliesModal'])
defineProps({
  replies: {
    type: Number,
    required: true,
  },
})

const openCreateRepliesModal = () => {
  emits('createRepliesModal')
}
</script>
<style scoped>
.replies-count {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e8f0;
  border-radius: 4px;
  padding: 8px;
  width: 46px;
  height: 26px;
}
.replies-zero {
  color: #94a3b8;
}
.gap-4 {
  gap: 8px;
}
</style>
