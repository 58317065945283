<template>
  <div class="d-flex" v-if="getPagesStructure.length">
    <Base-Select
      class="w-100 mr-1"
      :customOptions="getPagesStructure"
      optionLabelKey="name"
      v-model="section"
      label="Data Section"
      @update:modelValue="onSectionChange"
    />
    <Base-Select
      :key="subsectionKey"
      class="w-100 ml-1"
      :customOptions="getChildrenStructure"
      optionLabelKey="name"
      v-model="subsection"
      label="Data Subsection"
      @update:modelValue="onFilterChange"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, getCurrentInstance, computed, onMounted } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import pagesStructure from '@/constants/pages-structure.js'
import { useRoute } from 'vue-router'

const route = useRoute()
const { proxy } = getCurrentInstance()

const section = ref(1)
const subsection = ref(101)
const subsectionKey = ref(0)
const isInitialized = ref(false)

const emit = defineEmits(['filterChange'])

const onFilterChange = () => {
  const parent = pagesStructure.find((item) => item.id === section.value)
  const child = parent?.children.find((child) => child.id === subsection.value)
  emit('filterChange', child, parent)
  subsectionKey.value += 1
}

const onSectionChange = () => {
  subsectionKey.value += 1
  if (isInitialized.value) {
    subsection.value = null
  }
}

onMounted(() => {
  if (route.query.subject_name) {
    section.value = 2
    route.query.fromComponent ? (subsection.value = 215) : (subsection.value = 201)
  }
  isInitialized.value = true
  onFilterChange()
})

const getPagesStructure = computed(() => {
  return pagesStructure.filter((parent) =>
    parent.children.some((child) => proxy.hasPermission(child.permission))
  )
})

const getChildrenStructure = computed(() => {
  const parent = getPagesStructure.value.find((item) => item.id === section.value)
  return parent?.children.filter((child) => proxy.hasPermission(child.permission)) || []
})
</script>
