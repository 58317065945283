<template>
  <div class="item" ref="scrollItem" :value="source" @click="handleClick" :class="computedClass">
    <div v-if="source?.countryFlag" class="country-flag">
      <i :class="['gigflag', `gigflag-${source.countryFlag.toLowerCase()}`]"></i>
    </div>

    <div class="id text-truncate">
      {{ prettifiedLabel }}
    </div>

    <div v-if="source?.pages_count" class="pages-count">({{ source.pages_count }})</div>
  </div>
</template>

<script>
import mixins from '../../../mixins/ChildToParent'

export default {
  name: 'ScrollItem',
  mixins: [mixins],
  inject: ['onChangeItem'],
  props: {
    source: {
      type: [Object, String, Number],
      default: null,
    },
    dataFromParent: {
      type: [String, Number, null],
      default: null,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    pointer: {
      type: [Number, String],
      default: 0,
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    extraOptionLabelKey: {
      type: String,
      default: null,
    },
    removeActiveText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClass() {
      // Determine the value to compare: if source is an object, extract the value via trackBy; else use source directly.
      const value =
        typeof this.source === 'object' && this.source !== null ? this.source[this.trackBy] : this.source

      return {
        active: this.dataFromParent === value,
        'active-item': this.pointer === value && this.dataFromParent !== value && !this.removeActiveText,
        'active-item-remove':
          this.pointer === value && this.dataFromParent === value && !this.removeActiveText,
      }
    },
    prettifiedLabel() {
      let label = ''

      if (this.source && typeof this.source === 'object') {
        if (this.optionLabelKey in this.source) {
          const prefix =
            this.extraOptionLabelKey && this.source[this.extraOptionLabelKey]
              ? `${this.source[this.extraOptionLabelKey]} > `
              : ''
          label = prefix + this.source[this.optionLabelKey]
        } else if ('name' in this.source && this.source.name) {
          label = this.source.name
        } else {
          label = String(this.source)
        }
      } else {
        label = String(this.source)
      }

      return this.$prettyLabels(label)
    },
  },
  methods: {
    handleClick() {
      this.onChangeItem(this.source)
    },
  },
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  align-items: center;
  padding: 1em;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f6f6f6;
    color: #17182f;
  }
}

.active,
.active-item {
  background-color: #f6f6fb !important;
  color: #17182f !important;
}

.active-item::after,
.active-item-remove::after {
  position: absolute;
  right: 10px;
}

.active-item::after {
  content: 'Press enter to select';
}

.active-item-remove::after {
  content: 'Press enter to remove';
}

.country-flag {
  margin-top: 4px;

  i {
    margin-right: 4px;
  }
}

.pages-count {
  font-size: 0.85em;
  color: #999;
}
</style>
