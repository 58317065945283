<template>
  <div>
    <base-input v-bind="getAttributes()" v-if="['text'].includes(field.type)" v-model="field.value" />
    <base-color
      v-bind="getAttributes()"
      v-else-if="['colour_picker'].includes(field.type)"
      v-model="field.value"
    />
    <base-input
      v-bind="getAttributes()"
      v-else-if="field.type === 'number'"
      type="number"
      v-model="field.value"
    />
    <base-textarea v-bind="getAttributes()" v-else-if="field.type === 'textarea'" v-model="field.value" />
    <base-date v-bind="getAttributes()" v-else-if="field.type === 'date'" type="date" v-model="field.value" />
    <base-date
      v-bind="getAttributes()"
      v-else-if="field.type === 'date_time'"
      type="date_time"
      v-model="field.value"
      :config="getDatePickerConfig()"
    />
    <base-checkbox
      v-bind="getAttributes()"
      v-else-if="field.type === 'checkbox'"
      v-model="field.value"
      :label="field.name"
    />
    <base-rich-text
      v-bind="getAttributes()"
      v-else-if="field.type === 'html'"
      :modelValue="field.value"
      :siteId="siteId"
      :marketId="marketId"
      @valueChanged="valueChanged"
      height="120"
    />
    <base-select
      v-bind="getAttributes()"
      v-else-if="['list-one'].includes(field.type)"
      :key="field.key"
      :field="field.name"
      :modelValue="field.value"
      :optionLabelKey="field.selectLabel"
      :customOptions="field.options"
      :trackBy="field.trackBy"
      :loadOnMount="false"
      @update:modelValue="syncValues"
      :path="field.search_url"
      :countryFlag="field.country_flag"
    />
    <base-select-simple
      v-bind="getAttributes()"
      v-else-if="['list-one-simple'].includes(field.type)"
      :key="field.key"
      :field="field.name"
      :modelValue="field.value"
      :optionLabelKey="field.selectLabel"
      :customOptions="field.options"
      :trackBy="field.trackBy"
      :loadOnMount="false"
      @update:modelValue="syncValues"
      :path="field.search_url"
    />
    <base-multiselect
      v-bind="getAttributes()"
      v-else-if="['list-many'].includes(field.type)"
      :field="field.name"
      :value="field.value"
      :selectLabel="field.selectLabel"
      :customOptions="field.options"
      :trackByOption="field.trackBy"
      @sync="syncValues"
      :path="field.search_url"
    />
    <base-tag
      v-bind="getAttributes()"
      v-else-if="field.type == 'tag'"
      :field="field.name"
      v-model="field.value"
      :value="field.value"
    />
    <media
      v-bind="getAttributes()"
      v-else-if="field.type === 'image_upload'"
      v-model="field.value"
      :siteSpecific="siteSpecific"
    />
    <model-select
      v-bind="getAttributes()"
      v-else-if="['menu', 'operator', 'game'].includes(field.type)"
      :parameters="{
        site_id: siteId,
        market_id: marketId,
        affiliate_id: affiliateId,
        with_count: 'items',
      }"
      :custom-label="`${field.label} (${field.type})`"
      :model="field.type"
      :value="field.value"
      :value-label="field.value_label"
      @valuePicked="valueChanged"
    />
    <div v-if="showLinkToMenuEdit && field.type === 'menu'">
      <router-link :to="'/site-menus/' + field.value + '/items'" target="_blank">Edit menu items</router-link>
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import BaseColor from '@atoms/fields/base-color.vue'
import BaseMultiselect from './base-multiselect.vue'
import BaseTag from '@atoms/fields/base-tag.vue'
import BaseRichText from '@atoms/fields/base-rich-text.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import Media from '@molecules/fields/media.vue'
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import BaseSelect from './base-select.vue'
import BaseSelectSimple from './base-select-simple.vue'

export default {
  props: {
    field: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    marketId: {
      default: () => null,
    },
    siteId: {
      default: () => null,
    },
    affiliateId: {
      default: () => null,
    },
    siteSpecific: {
      type: Boolean,
      default: false,
    },
    searchBy: {
      type: String,
      default: null,
    },
    showLinkToMenuEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseInput,
    BaseTag,
    BaseCheckbox,
    BaseRichText,
    BaseDate,
    BaseColor,
    ModelSelect,
    BaseMultiselect,
    Media,
    BaseSelect,
    BaseSelectSimple,
    BaseTextarea,
  },
  methods: {
    valueChanged(value, valueLabel) {
      this.field.value = value
      this.field.value_label = valueLabel
      if (this.field.extra_field_value) {
        this.field.extra_field_value.value = value
      }
      this.field.updated = true
    },
    // temporary for base-multiselect (sync method really needs to be refactored)
    syncValues(value) {
      this.field.value = value
    },
    getAttributes() {
      return {
        label: this.field.label,
        required: this.field.required == 1,
        disabled: this.disabled,
        readOnly: this.readOnly,
        helpText: this.field.help_text,
        placeholder: this.field.placeholder,
        searchBy: this.searchBy,
      }
    },
    getDatePickerConfig() {
      return {
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        enableTime: true,
      }
    },
  },
}
</script>
