<template>
  <div>
    <div v-bind:class="{ 'modal-body': inModal, 'card-body': inCard }">
      <form class="form-style" v-on:submit.prevent="validateBeforeSubmit">
        <div class="d-flex flex-wrap justify-content-between">
          <div class="flex-grow-1 mt-2" v-if="inCard">
            <h4 class="d-inline mb-0">{{ $route.meta.name }}</h4>
          </div>

          <div
            class="pr-0 d-flex flex-nowrap justify-content-between"
            v-if="checkIfEditCard"
            v-bind:class="{ 'card-footer pb-0': inCard, 'modal-footer': inModal }"
          >
            <div @click="openModalInformation()" v-if="confluenceId" class="btn btn-outline-info btn-md ml-2">
              <i class="uil uil-info-circle"></i>
              Help
            </div>

            <button
              v-if="showRemove && onRemove && methodAllowed('delete', path)"
              type="button"
              class="btn btn-danger ml-2"
              v-bind:class="{ loading: isLoading }"
              v-on:click.stop="validateBeforeDelete"
              :disabled="showColaborativeNotification === true || isDisabled"
            >
              <i class="uil uil-trash pr-2"></i>
              Delete
            </button>

            <button
              v-if="
                customCreateActionButton.action && shouldShowAccordingToRoles(customCreateActionButton.roles)
              "
              type="button"
              class="btn btn-info ml-2"
              v-on:click.stop="customCreateActionButton.action"
              v-bind:class="{ loading: isLoading }"
            >
              <i :class="customCreateActionButton.icon"></i>
              {{ customCreateActionButton.title }}
            </button>

            <button
              v-if="showSubmit && methodAllowed('put', path)"
              class="btn btn-primary ml-2"
              :disabled="showColaborativeNotification === true || isDisabled"
              v-bind:class="{ loading: isLoading }"
            >
              <i class="uil uil-check"></i>
              {{ submitLabel }}
            </button>
          </div>
        </div>

        <div class="mb-2 mt-2">
          <colaborative-edit-notification
            v-if="showColaborativeNotification"
            :route_path="$route.fullPath"
            :username="username"
          />
        </div>

        <dynamic-fields
          ref="form"
          v-bind:class="{ 'modal-form-two-column': twoColumnLayout }"
          :form-type="formType"
          :formFields="formFields"
          :filter="filter"
          :disabled="isDisabled"
          v-if="object"
          :object="object"
          :fieldClass="fieldClass"
          @notifyExtraFields="notifyExtraFields"
          :closeOnDestroy="closeOnDestroy"
          :parameters="parameters"
          @click="handleFormClick"
        />

        <div v-if="extraFields.length > 0">
          <hr />
          <div
            class="d-flex align-items-center justify-content-between"
            @click="toggleFieldCollapse('extra_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">Extra Fields</label>

              <span
                data-tooltip="These are extra fields added from the Affiliate page."
                data-tooltip-color="danger"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.extra_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.extra_fields">
            <dynamic-fields
              :siteSpecific="true"
              ref="extra_fields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="extraFields"
              :filter="filter"
              v-if="extraFields.length > 0"
              :object="extraFieldsObject"
              :closeOnDestroy="closeOnDestroy"
              :parameters="parameters"
              @click="handleFormClick('extra_fields')"
            />
          </div>
        </div>
        <div v-if="advancedRatings.length > 0">
          <hr />

          <div class="d-flex">
            <label class="text-muted">Advanced Rating</label>

            <span
              data-tooltip="These are advanced ratings added from the Affiliate page."
              data-tooltip-color="danger"
              data-tooltip-position="right center"
              class="ml-2 mb-2"
            >
              <i class="uil uil-info-circle"></i>
            </span>
          </div>
          <dynamic-fields
            :siteSpecific="true"
            ref="advanced_ratings"
            :form-type="formType"
            :disabled="isDisabled"
            :formFields="advancedRatings"
            :filter="filter"
            :object="advancedRatingsObject"
            :closeOnDestroy="false"
            :parameters="parameters"
            v-if="advancedRatings.length > 0"
          />
        </div>
        <div v-if="sportsBookFields?.length > 0 && checkForType(object)">
          <hr />
          <div
            class="d-flex justify-content-between align-items-center"
            @click="toggleFieldCollapse('sportsbook_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">Sportsbook Fields</label>
              <span
                data-tooltip="These are sportsbook fields that show only when an Operator has sportsbook as operator type ."
                data-tooltip-color="inverse"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.sportsbook_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.sportsbook_fields">
            <dynamic-fields
              v-if="sportsBookFields?.length > 0"
              ref="sportsbookfields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="sportsBookFields"
              :filter="filter"
              :object="sportsBookFieldValues"
              :closeOnDestroy="closeOnDestroy"
              :parameters="parameters"
              @click="handleFormClick"
            />
          </div>
        </div>
        <div v-if="supportTypeFields?.length > 0">
          <hr />
          <div
            class="d-flex justify-content-between align-items-center"
            @click="toggleFieldCollapse('support_type_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">Support Type Fields</label>
              <span
                data-tooltip="These are support type fields that are specific for Time2Play."
                data-tooltip-color="inverse"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.support_type_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.support_type_fields">
            <dynamic-fields
              v-if="supportTypeFields?.length > 0"
              ref="supportTypeFields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="supportTypeFields"
              :filter="filter"
              :object="supportTypeFieldValues"
            />
          </div>
        </div>
        <div v-if="paymentLimitFields?.length > 0">
          <hr />
          <div
            class="d-flex justify-content-between align-items-center"
            @click="toggleFieldCollapse('payout_limits_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">Payment Limits</label>
              <span
                data-tooltip="These are game fields that are specific for Time2Play."
                data-tooltip-color="inverse"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.payout_limits_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.payout_limits_fields">
            <dynamic-fields
              v-if="paymentLimitFields?.length > 0"
              ref="paymentLimitFields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="paymentLimitFields"
              :filter="filter"
              :object="paymentLimitFieldsValues"
              :closeOnDestroy="closeOnDestroy"
              :parameters="parameters"
              @click="handleFormClick"
            />
          </div>
        </div>
        <div v-if="gameFields?.length > 0">
          <hr />
          <div
            class="d-flex justify-content-between align-items-center"
            @click="toggleFieldCollapse('game_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">No of</label>
              <span
                data-tooltip="These are fields that are specific for Time2Play."
                data-tooltip-color="inverse"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.game_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.game_fields">
            <dynamic-fields
              v-if="gameFields?.length > 0"
              ref="gameFields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="gameFields"
              :filter="filter"
              :object="gameFieldValues"
              :closeOnDestroy="closeOnDestroy"
              :parameters="parameters"
              @click="handleFormClick"
            />
          </div>
        </div>
        <div v-if="topExampleFields?.length > 0">
          <hr />
          <div
            class="d-flex justify-content-between align-items-center"
            @click="toggleFieldCollapse('top_example_fields')"
          >
            <div class="d-flex align-items-center">
              <label class="text-muted cursor-pointer">Top Examples</label>
              <span
                data-tooltip="These are top examples fields that are specific for Time2Play."
                data-tooltip-color="inverse"
                data-tooltip-position="right center"
                class="ml-2 mb-1"
              >
                <i class="uil uil-info-circle"></i>
              </span>
            </div>
            <i
              :class="
                collapsedSections.top_example_fields
                  ? 'uil uil-angle-down cursor-pointer mr-4'
                  : 'uil uil-angle-up cursor-pointer mr-4'
              "
            ></i>
          </div>
          <div v-show="!collapsedSections.top_example_fields">
            <dynamic-fields
              v-if="topExampleFields?.length > 0"
              ref="topExampleFields"
              :form-type="formType"
              :disabled="isDisabled"
              :formFields="topExampleFields"
              :filter="filter"
              :object="topExampleFieldValues"
              :closeOnDestroy="closeOnDestroy"
              :parameters="parameters"
              @click="handleFormClick"
            />
          </div>
        </div>
        <button type="submit" hidden />
      </form>
    </div>

    <div
      class="text-right"
      v-if="!checkIfEditCard"
      v-bind:class="{ 'card-footer': inCard, 'modal-footer': inModal }"
    >
      <button
        v-if="showRemove && onRemove && methodAllowed('delete', path)"
        type="button"
        class="btn btn-danger mr-3"
        v-bind:class="{ loading: isLoading }"
        v-on:click.stop="validateBeforeDelete"
        :disabled="showColaborativeNotification === true || isDisabled"
      >
        <i class="uil uil-trash pr-2"></i>
        Delete
      </button>

      <button
        v-if="customCreateActionButton.action && shouldShowAccordingToRoles(customCreateActionButton.roles)"
        type="button"
        class="btn btn-info mr-3"
        v-on:click.stop="customCreateActionButton.action"
        v-bind:class="{ loading: isLoading }"
        :disabled="showColaborativeNotification === true || isDisabled"
      >
        <i :class="customCreateActionButton.icon"></i>
        {{ customCreateActionButton.title }}
      </button>

      <button
        @click="validateBeforeSubmit"
        v-if="showSubmit && methodAllowed('put', path)"
        class="btn btn-primary"
        :disabled="showColaborativeNotification === true || isDisabled"
        v-bind:class="{ loading: isLoading }"
      >
        <i class="uil uil-check"></i>
        {{ submitLabel }}
      </button>
    </div>

    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import _ from 'lodash'
import patterns from '@constants/regex-patterns.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification.vue'
import DynamicFormHelper from '@mixins/DynamicFormHelper.js'
export default {
  components: {
    InformationModal,
    ColaborativeEditNotification,
  },
  mixins: [DynamicFormHelper],
  data() {
    return {
      isDisabled: false,
      isLoading: false,
      confluenceId: 0,
      showColaborativeNotification: false,
      // extraFieldsCollapsed: true,
      // collapsed: true,
      clickedOnForm: false,
      collapsedSections: {
        extra_fields: true,
        sportsbook_fields: true,
        payout_limits_fields: true,
        support_type_fields: true,
        game_fields: true,
        top_example_fields: true,
      },
    }
  },
  computed: {
    visibleFields() {
      return this.formFields.filter((field) => {
        return this.canShow(field.name, field.only_edit)
      })
    },
    checkIfEditCard() {
      return this.formType === 'edit' && this.inCard && !this.inModal
    },
    getInitFormObject() {
      return this.$store.state.dynamicForm.initFormObject
    },
    getFormObject() {
      return this.$store.state.dynamicForm.formObject
    },
  },
  props: {
    isCentered: {
      type: Boolean,
      default: () => true,
    },
    inModal: {
      type: Boolean,
      default: () => false,
    },
    inCard: {
      type: Boolean,
      default: () => false,
    },
    fieldClass: {
      type: String,
    },
    actionButtonsTop: {
      type: Boolean,
      default: () => false,
    },
    showSubmit: {
      type: Boolean,
      default: () => true,
    },
    formType: {
      required: true,
      type: String,
    },
    onSubmit: {
      required: false,
      type: [Function],
    },
    onRemove: {
      required: false,
      type: Function,
    },
    object: {
      required: true,
    },
    extraFieldsObject: {
      default: () => null,
    },
    advancedRatingsObject: {
      default: () => null,
    },
    twoColumnLayout: {
      default: () => false,
      type: Boolean,
    },
    filter: {
      type: Array,
      required: false,
    },
    submitLabel: {
      type: String,
      default: () => 'Submit',
    },
    path: {
      type: String,
    },
    // passed to base-model-select for site_id / market_id filters
    parameters: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Array,
      required: true,
      default: () => [],
    },
    extraFields: {
      type: Array,
      default: () => [],
    },
    advancedRatings: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    customCreateActionButton: {
      type: Object,
      default: () => {
        return {
          action: null,
          title: 'Primary',
          icon: 'uil uil-share-alt',
          roles: [],
        }
      },
    },
    showRemove: {
      type: Boolean,
      default: () => true,
    },
    removeRoles: {
      type: Array,
      default: () => [],
    },
    fixedButtons: {
      type: Boolean,
      default: () => false,
    },
    closeOnDestroy: {
      type: Boolean,
      default: () => true,
    },
    pusherModel: {
      type: String,
      default: () => {
        return 'default'
      },
    },
    sportsBookFields: {
      type: Array,
      default: () => [],
    },
    sportsBookFieldValues: {
      type: Array,
      default: () => [],
    },
    supportTypeFields: {
      type: Array,
      default: () => [],
    },
    supportTypeFieldValues: {
      type: Array,
      default: () => [],
    },
    paymentLimitFields: {
      type: Array,
      default: () => [],
    },
    paymentLimitFieldsValues: {
      type: Array,
      default: () => [],
    },
    gameFields: {
      type: Array,
      default: () => [],
    },
    gameFieldValues: {
      type: Array,
      default: () => [],
    },
    topExampleFields: {
      type: Array,
      default: () => [],
    },
    topExampleFieldValues: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    patterns.dynamic_form.some(
      function (item) {
        let match = this.$route.path.match(item.regex)
        if (match && match.length > 0 && match[0] === this.$route.path) {
          this.confluenceId = item.id
          return true
        }
      }.bind(this)
    )
  },
  beforeMount() {
    this.isDisabled = true
    // need to be set to null by default, otherwise it will show values from other components
    this.$store.dispatch('media/setSiteAndMarket', {
      site_id: null,
      market_id: null,
    })
    this.checkExtraFieldsRequired()
  },
  mounted() {
    setTimeout(() => {
      this.isDisabled = this.disabled || false
    }, 1500)
  },
  watch: {
    extraFieldsObject: {
      handler: function (newValue) {
        this.$store.commit('dynamicForm/setFormObject', { ...this.object, ...newValue })
        if (!_.isEqual(this.getInitFormObject, this.getFormObject)) {
          this.$store.commit('dynamicForm/setFormSubmitted', false)
        }
      },
      deep: true,
    },
    advancedRatingsObject: {
      handler: function (newValue) {
        this.$store.commit('dynamicForm/setFormObject', { ...this.object, ...newValue })
        if (!_.isEqual(this.getInitFormObject, this.getFormObject)) {
          this.$store.commit('dynamicForm/setFormSubmitted', false)
        }
      },
      deep: true,
    },
    object: {
      handler: function (newValue) {
        this.$store.commit('dynamicForm/setFormObject', { ...this.object, ...newValue })
        if (!_.isEqual(this.getInitFormObject, this.getFormObject)) {
          this.$store.commit('dynamicForm/setFormSubmitted', false)
        }
      },
      deep: true,
    },
  },
  methods: {
    checkForType(object) {
      if (object.type === 'sportsbook') {
        return true
      }
      if (Array.isArray(object.game_types)) {
        return object.game_types.includes('sportsbook')
      }
      return false
    },
    checkExtraFieldsRequired() {
      const extraFieldsRequired =
        this.extraFields.length > 0 && this.extraFields?.filter((field) => field.required === true)
      if (extraFieldsRequired.length > 0 && this.collapsedSections.extra_fields) {
        this.collapsedSections.extra_fields = false
      }
    },
    toggleFieldCollapse(field) {
      this.collapsedSections[field] = !this.collapsedSections[field]
    },
    handleFormClick(extraFields) {
      let efObject = {}
      if (extraFields == 'extra_fields') {
        efObject = Object.assign({}, this.extraFieldsObject)
      }

      if (!Object.keys(this.$store.state.dynamicForm.initFormObject).length && this.formType === 'edit') {
        this.$store.commit('dynamicForm/setInitFormObject', { ...this.object, ...efObject })
        this.$store.commit('dynamicForm/setFormObject', { ...this.object, ...efObject })
      }
    },
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    getValue(field) {
      if (field.type === 'label') {
        return this.findInObject(field.value_key.split('.'), this.object)
      }

      if (field.type === 'date') {
        return field.value
      }

      if (Object.entries(this.object).length === 0 && field.create_defaults) {
        return field.create_defaults
      }

      if (field.value_key) {
        const value = this.findInObject(field.value_key.split('.'), this.object)
        const trackBy = field.trackBy || 'id'
        const selectLabel = field.selectLabel || 'name'

        if (typeof value === 'object' && value !== null) {
          this.object[field.name] = value[trackBy]

          if (field.value_key === 'site_market.market.label') {
            return { id: this.object[field.name], market: { label: value } }
          }

          return value
        }

        return { [trackBy]: this.object[field.name], [selectLabel]: value }
      }

      return this.object[field.name]
    },

    syncValues(field, value, key = 'id') {
      if (value) {
        if (key === 'tag-input') {
          // one item (list-one)
          this.object[field] = value
        } else if (Array.isArray(value)) {
          this.object[field] = value.map((item) => item[key])
        } else if (_.isObject(value)) {
          // one item (list-one)
          this.object[field] = value[key]
        } else {
          this.object[field] = value
        }
      } else if (value === null) {
        // this is used for removing image from dropzone, this nulls the logo_field_id
        this.object[field] = null
      }
    },
    process(object) {
      return new Promise((resolve) => resolve(object))
    },
    canShow(field, edit_only) {
      if (this.formType !== 'edit' && edit_only) {
        return false
      }
      return _.indexOf(this.filter, field) === -1
    },
    disableEdit(disable) {
      this.isDisabled = disable

      if (this.$refs.form) {
        this.$refs.form.disableEdit(disable)
      }

      if (this.$refs.extra_fields) {
        this.$refs.extra_fields.disableEdit(disable)
      }
    },
    validateBeforeDelete() {
      if (this.showColaborativeNotification) {
        return
      }
      this.onRemove()
    },
    validateBeforeSubmit() {
      if (this.showColaborativeNotification) {
        return
      }

      if (this.$refs.form && Object.hasOwn(this.$refs.form.object, 'extra_fields')) {
        delete this.$refs.form.object.extra_fields
      }

      if (this.$refs.form && Object.hasOwn(this.$refs.form.object, 'advanced_ratings')) {
        delete this.$refs.form.object.advanced_ratings
      }

      if (this.extraFields.length > 0 && this.$refs.extra_fields && this.$refs.extra_fields.object) {
        this.$emit('updateExtraFields', this.$refs.extra_fields.object)
      }

      if (this.advancedRatings.length > 0) {
        this.$emit('updateAdvancedRatings', this.$refs.advanced_ratings.object)
      }

      this.$nextTick(() => {
        this.removeBeforeUnloadEvent()
        this.isLoading = true
        this.onSubmit()
        this.$store.commit('dynamicForm/setFormSubmitted', true)
        this.$store.commit('dynamicForm/setInitFormObject', {})
      })
    },
    notifyFields(ref) {
      this.$refs.form.$refs[ref] && this.$refs.form.$refs[ref][0].clearAndEnable()
      if (this.$refs.extra_fields) {
        this.$refs.extra_fields[ref][0].clearAndEnable()
      }
    },
    notifyExtraFields(method) {
      this.$emit('notifyExtraFields', method)
    },
    shouldShowAccordingToRoles(roles) {
      return roles.length == 0 ? true : this.hasRoles(roles)
    },
    loading(loading) {
      this.isLoading = loading
    },
  },
}
</script>
