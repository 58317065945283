<template>
    <div>
        <data-table 
            model="SportCompetitions"
            ref="list"
            service-file="SportCompetitionsService"
            :path="path"
            :columns="columns()"
            :createDefaults="{ active: true }"
            :searchDelay="200"
        />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useTitle } from '@/composables/useTitle.js'
import TableColumnTemplate from '@constants/table-column-template.js'

const list = ref(null)
const path = ref('hercules/globals/sport-competitions')
const { setTitle } = useTitle()

const columns = () => {
    return [
        { 
            field: 'id', 
            type: 'text', 
            filterable: true 
        },
        {
            field: 'name',
            label: 'Name',
            type: 'text',
            filterable: true
        },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        { 
            field: 'updated_at', 
            type: 'text', 
            template: TableColumnTemplate.RELATIVE_DATE,
            filterable: false 
        }
    ]
}

onMounted(() => {
  setTitle()
})
</script>