<template>
  <div>
    <activity-logs-filters @filterChange="onFilterChange" />
    <data-table
      :column-templates="columnTemplates()"
      :path="path"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :enableRowClick="false"
      :showCreate="false"
      :searchDelay="200"
      :showRemove="false"
      ref="table"
      :key="refreshTable"
    />
    <new-activity-logs-list-modal ref="modal" model="Log" />
  </div>
</template>

<script setup lang="jsx">
import { ref, onMounted, computed, watch } from 'vue'
import { useTitle } from '@/composables/useTitle.js'
import TableColumnTemplate from '@constants/table-column-template.js'
import ActivityLogsFilters from '@atoms/activityLogs/activity-logs-filters.vue'
import NewActivityLogsListModal from '@atoms/logs/new-activity-logs-list-modal.vue'
import moment from 'moment'
import { useStore } from 'vuex'

const store = useStore()
const table = ref('table')
const refreshTable = ref(0)
const path = ref('hercules/operators/logs')
const modelMappings = ref([])
const { setTitle } = useTitle()
const modal = ref(null)

const dateValue = computed(() => store.state['data-table']?.dateCleared)

watch(dateValue, (newVal) => {
  if (newVal) {
    refreshTable.value++
    store.commit('data-table/setDateCleared', false)
  }
})

const onFilterChange = (child, parent) => {
  if (!child || !child.name) {
    return
  }

  const { name, endpoint, customModelOptions } = child

  let pathValue

  switch (name) {
    case 'Tracking links':
    case 'Content':
      pathValue = `hercules/${endpoint}`
      break
    case 'Users':
    case 'Roles':
      pathValue = `${endpoint}?subject_group=${name}`
      break
    default:
      pathValue = `hercules/${endpoint}?subject_group=${name}`
  }

  path.value = pathValue
  modelMappings.value = customModelOptions
  refreshTable.value += 1
}

const columns = () => {
  return [
    {
      field: 'created_at',
      label: 'Moments ago',
      type: 'text',
      template: TableColumnTemplate.RELATIVE_DATE,
      filterable: false,
    },
    { field: 'subject_name', type: 'text', filterable: true, label: 'Data' },
    {
      field: 'causer_type',
      basicFilterType: 'select',
      label: 'User',
      filterable: true,
      path: 'backoffice/users/byRole',
      optionLabelKey: 'name',
      fetch_on_load: false,
      trackBy: 'name',
      searchBy: 'name',
      showAllOption: true,
      showEmptyNull: true,
    },
    {
      field: 'subject_type',
      type: 'text',
      label: 'Data Description',
      filterable: true,
      advancedFilter: true,
      clearable: true,
      allowNullOption: true,
      basicFilterType: 'select',
      optionLabelKey: 'label',
      customOptions: modelMappings.value,
      width: '300px',
    },
    { field: 'subject_id', type: 'text', filterable: true, label: 'Data ID' },
    {
      field: 'from',
      label: 'Date',
      advancedFilter: true,
      basicFilterType: 'vue-date-picker',
      filterable: true,
    },
    {
      field: 'action',
      type: 'text',
      filterable: true,
      template: TableColumnTemplate.LOGS_STATUS,
    },
  ]
}

const rowActions = () => {
  return [
    {
      action: showDiff,
      title: 'Details',
      icon: 'uil uil-eye',
      class: 'btn-info',
    },
  ]
}

const columnTemplates = () => {
  return {
    action: function (row) {
      return row.event
    },
    subject_name: function (row) {
      return row.subject_name
    },
    subject_id: function (row) {
      return row.subject_id
    },
    causer_type: function (row) {
      return row.causer_type
    },
    subject_type: function (row) {
      let modelName = row.subject_type?.replace('App\\Models\\', '')
      let found = modelMappings.value && modelMappings.value.find((page) => page.id === modelName)

      return found ? found.label : modelName
    },
    from: function (row) {
      return moment(row.created_at).format('YYYY-MM-DD HH:mm:ss')
    },
  }
}

const showDiff = (row) => {
  modal.value.object = row
  modal.value.show()
}

onMounted(() => {
  setTitle()
})
</script>
