const ids = {
  pages: {
    top: {
      id: 19246111,
    },
    general: {
      id: 19252489,
    },
    sections: {
      id: 19238717,
    },
    seo: {
      id: 19245173,
      search_engine_optimization: {
        id: 19243544,
      },
      robot_options: {
        id: 19248602,
      },
      hreflang: {
        id: 19246352,
      },
      json_schema: {
        id: 19248788,
      },
    },
    advanced: {
      change_type_page: {
        id: 19246003,
      },
      clone_page: {
        id: 19240071,
      },
      delete_page: {
        id: 19250225,
      },
      raw_jw_inj: {
        id: 19242635,
      },
    },
  },
  sites: {
    top: {
      id: 19249387,
    },
    general: {
      id: 19248348,
    },
    breadcrumbs: {
      id: 19238994,
    },
    module_colors: {
      id: 19251897,
    },
    module_styles: {
      id: 19243421,
    },
    categories: {
      id: 19239099,
    },
    markets: {
      id: 19249453,
    },
    schema: {
      id: 19241154,
    },
  },
  menus: {
    top: {
      id: 19249239,
    },
    general: {
      id: 19240755,
    },
    items: {
      id: 19240270,
    },
  },
  banners: {
    top: {
      id: 19246773,
    },
    general: {
      id: 19238564,
    },
    items: {
      id: 19247416,
    },
  },
  authors: {
    top: {
      id: 19248810,
    },
    general: {
      id: 19251406,
    },
    pages: {
      id: 19250808,
    },
  },
  redirects: {
    id: 19239248,
  },
  page_types: {
    top: {
      id: 19240784,
    },
    general: {
      id: 19242310,
    },
    blocks: {
      id: 19248327,
    },
    page_styles: {
      id: 19239912,
    },
    extra_fields: {
      id: 19238498,
    },
    seo: {
      id: 19250289,
    },
    autogenerated_content: {
      id: 2801401938,
    },
  },
  reports: {
    id: 2411724981,
  },
  user_comments: {
    id: 19250569,
  },
}

export default ids
