<template>
  <data-table
    service-file="AffiliateAdvancedOperatorRatingsService"
    model="AffiliateAdvancedOperatorRatings"
    :path="path"
    ref="list"
    :columns="columns()"
    :columnTemplates="columnTemplates()"
    :create-defaults="{ affiliate_id: affiliate_id }"
    :serviceParams="[affiliate_id]"
    :extraDeleteInfo="{
      title: 'Warning',
      message: '',
      extraMessage: 'Deleting this field will also delete all sites data values for this!',
      boldText: 'font-weight-bold',
    }"
  />
</template>

<script setup>
import { getCurrentInstance, ref, onMounted, computed } from 'vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()
const { proxy } = getCurrentInstance()
const affiliate_id = ref(route.params['id'])

onMounted(() => {
  proxy.setTitle(`${store.state.editPage.model ? store.state.editPage.model.website + ':' : ''} Markets Data`)
})

const columns = () => {
  return [
    { field: 'rating_name', type: 'text', filterable: true },
    { field: 'market', type: 'text', label: 'Market', filterable: true },
    { field: 'type', type: 'text', label: 'Type', filterable: true },
    { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
  ]
}

const path = computed(
  () =>
    `hercules/operators/affiliate-advanced-ratings?affiliate_id=${affiliate_id?.value}&with=markets,operator_type`
)

const columnTemplates = () => {
  return {
    market(row) {
      return row.markets?.map((market) => market.label).join(', ')
    },
    type(row) {
      return row.operator_type?.map((type) => type.name).join(', ')
    },
  }
}
</script>
