<template>
  <div>
    <base-input type="text" label="name" :type="text" v-model="row.name" class="w-100" />
    <div class="d-flex align-items-center gap-4 w-100">
      <div class="w-50">
        <base-select
          label="Author"
          :clearable="false"
          v-model="row.author_id"
          ref="providerSelect"
          :path="`hercules/sites/authors?site_id=${siteId}`"
          option-label-key="name"
          class="pr-2 mb-2"
        />
      </div>
      <div class="w-50">
        <base-input type="text" label="email" :type="text" v-model="row.email" class="w-100" />
      </div>
    </div>
    <div class="d-block align-items-center gap-4 w-100">
      <base-number-input type="custom-number" label="rating" :type="text" v-model="row.rate" class="w-100" />
      <base-checkbox label="Approved" v-model="row.approved" class="d-flex place-items-center" />
      <base-textarea label="Comments" ref="input" :rows="5" v-model="row.comment" />
    </div>
  </div>
  <div class="modal-footer pt-3">
    <div class="text-right">
      <button type="submit" class="btn btn-danger" @click="deleteFN" :class="{ loading: isLoading }">
        <i class="uil uil-trash"></i>
        Delete
      </button>
    </div>
    <div class="text-right">
      <button type="submit" class="btn btn-primary" @click="updateFN" :class="{ loading: isLoading }">
        <i class="uil uil-check"></i>
        Save
      </button>
    </div>
  </div>
</template>
<script setup>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseNumberInput from '@atoms/fields/base-number-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import PageCommentService from '@services/PageCommentService.js'
import { getCurrentInstance, ref } from 'vue'

const isLoading = ref(false)
const emits = defineEmits(['closeModal'])
const props = defineProps({
  row: {
    type: Object,
    default: () => null,
  },
  siteId: {
    type: Number,
    default: () => null,
  },
})

const { proxy } = getCurrentInstance()

const updateFN = async () => {
  isLoading.value = true
  try {
    const response = await PageCommentService.update(props.row)
    if (response.data.messages) {
      proxy.showErrorMessage(response.data.messages[0])
    } else {
      proxy.showSuccessMessage('Comment updated')
      closeModal()
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const deleteFN = async () => {
  try {
    const response = await PageCommentService.remove(props.row)
    if (response.data.messages) {
      proxy.showErrorMessage(response.data.messages[0])
    } else {
      proxy.showSuccessMessage('Comment removed')
      closeModal()
    }
  } catch (error) {
    console.error(error)
  }
}

const closeModal = () => {
  emits('closeModal')
}
</script>
<style scoped>
.gap-4 {
  gap: 16px;
}
.modal-footer {
  padding: 0;
  margin-bottom: 16px;
}
</style>
