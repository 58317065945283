<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">Content Report</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <base-select
              label="Site"
              option-label-key="name"
              path="hercules/sites/sites?enable_content_type=1&orderBy=name&ascending=1"
              v-model="site"
              :loadOnMount="false"
              :required="true"
              trackBy="id"
            />
          </div>
        </div>
        <div class="row">
          <base-multiselect
            :multiple="true"
            field="market"
            :required="true"
            :loadOnMount="false"
            label="Markets"
            selectLabel="label"
            trackByOption="id"
            :path="`hercules/sites/markets/by-site/${site}?fields_only[]=id,&fields_only[]=label`"
            :value="market"
            class="col-10 pr-2 pl-2 mb-0"
            :helpText="helpText"
            :readOnly="!site"
            @sync="syncMarket"
          />
        </div>
        <div class="row">
          <base-multiselect
            :multiple="true"
            field="pageType"
            :required="true"
            :loadOnMount="false"
            label="Page Type"
            option-label-key="name"
            trackByOption="id"
            :path="`hercules/sites/templates?site_id=${site}&orderBy=name&ascending=1&market_id=${market}&fields_only[]=id,&fields_only[]=name`"
            :value="pageType"
            class="col-10 pr-2 pl-2 mb-0"
            @sync="syncTemplate"
          />
        </div>
      </div>
      <div class="card-footer">
        <button :class="{ loading: loading }" class="btn btn-primary icon-btn" @click="exportCsv">
          <i class="uil uil-file-export"></i>Export to CSV
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

const { proxy } = getCurrentInstance()
const site = ref(null)
const market = ref([])
const pageType = ref([])
const loading = ref(false)

const helpText = computed(() =>
  !site.value ? 'Please select a site first if you want to access all markets for that site' : ''
)

const syncTemplate = (_field, value, key) => {
  let newValue = null
  if (value && Array.isArray(value)) {
    newValue = value.map((item) => item[key])
  }
  pageType.value = newValue
}

const syncMarket = (_field, value, key) => {
  let newValue = null
  if (value && Array.isArray(value)) {
    newValue = value.map((item) => item[key])
  }
  market.value = newValue
}

const exportCsv = async () => {
  loading.value = true
  const filename = `content-report-${moment().format('YYYY-MM-DD-HHmm')}`

  const params = {
    site: site.value,
    market: market.value || [],
    pageType: pageType.value || [],
  }

  await proxy.$http
    .get('hercules/sites/content-type-report', { params })
    .then((response) => {
      if (response.data.messages) {
        proxy.showErrorMessages(response.data.messages)
      } else {
        const items = response.data.result
        if (items.length === 0) {
          proxy.showErrorMessages('No results for selected fields')
        } else {
          const csvExporter = new ExportToCsv({
            useKeysAsHeaders: true,
            filename,
          })
          csvExporter.generateCsv(items)
        }
      }
    })
    .catch(() => {
      proxy.showUnknownErrorMessage()
    })
    .finally(() => {
      loading.value = false
    })
}
</script>
