export default {
  namespaced: true,
  state: () => ({
    items: [],
    status: {},
  }),
  getters: {
    getItems: (state) => state.items,
    getStatus: (state) => state.status,
  },
  actions: {
    setStatus({ commit }, value) {
      commit('setStatus', value)
    },
    setItems({ commit }, value) {
      commit('setItems', value)
    },
    updateItemsSortOrder({ commit }, value) {
      commit('updateItemsSortOrder', value)
    },
    addItems({ commit }, items) {
      commit('addItems', items)
    },
    updateItem({ commit }, value) {
      commit('updateItem', value)
    },
    updateItemRating({ commit }, value) {
      commit('updateItemRating', value)
    },

    sortItems({ commit }, criteria) {
      commit('sortItems', criteria)
    },
  },
  mutations: {
    setStatus(state, value) {
      state.status = value
    },
    setItems(state, value) {
      state.items = value
    },
    updateItemsSortOrder(state, items) {
      state.items = items
    },
    addItems(state, newItems) {
      newItems.forEach((item) => {
        state.items.splice(item.sort_nr - 1, 0, item)
      })
    },
    updateItem(state, { index, item }) {
      let clone = [...state.items]
      clone[index] = item
      state.items = clone
    },
    updateItemRating(state, payload) {
      const { index, item } = payload
      let clone = [...state.items]
      const data = clone[index]

      Object.keys(item).forEach((key) => {
        const val = item[key]
        if (data.operator.affiliate_operators[0][key] !== undefined) {
          data.operator.affiliate_operators[0][key] = val
        }
      })

      state.items = clone
    },
    createSitesData(state, value) {
      const { result, item } = value
      let index = state.items.findIndex((x) => x.id === item.id)
      state.items[index].operator.affiliate_operators = result
    },
    sortItems(state, criteria) {
      if (criteria === 'Highest rating') {
        state.items.sort((a, b) => {
          if (b.operator.affiliate_operators[0]?.rating === a.operator.affiliate_operators[0]?.rating) {
            return a.operator.short_name.localeCompare(b.operator.short_name)
          }
          return b.operator.affiliate_operators[0]?.rating - a.operator.affiliate_operators[0]?.rating
        })
      } else if (criteria === 'Recently updated') {
        state.items.sort((a, b) => {
          return new Date(b.operator.updated_at) - new Date(a.operator.updated_at)
        })
      } else if (criteria === 'Newly added') {
        state.items.sort((a, b) => {
          return (
            new Date(b.operator.affiliate_operators[0]?.updated_at) -
            new Date(a.operator.affiliate_operators[0]?.updated_at)
          )
        })
      }

      state.items.forEach((item, index) => {
        item.sort_nr = index + 1
      })
    },
  },
}
