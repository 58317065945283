<template>
  <span :class="isMainLinkAddedClass" :title="description"></span>
</template>

<script>
export default {
  props: {
    isMainLinkAdded: {},
  },
  computed: {
    isMainLinkAddedClass() {
      if (this.isMainLinkAdded) {
        return `btn btn-sm ml-3 text-center btn-success uil uil-eye`
      }
      return `btn btn-sm ml-3 text-center btn-danger uil uil-exclamation-triangle`
    },
    description() {
      if (this.isMainLinkAdded) {
        return "Main tracking link is set"
      }
      return "Missing main tracking link"
    }
  }
}
</script>

<style></style>
