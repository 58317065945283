import AffiliateExtraFieldValuesService from '../services/AffiliateExtraFieldValuesService'
import AffiliateExtraFieldsService from '../services/AffiliateExtraFieldsService'
import FieldType from '@constants/field-type.js'
import moment from 'moment'
import AffiliateAdvancedRatingValuesService from '@services/AffiliateAdvancedRatingValuesService'

export default {
  getForm(operatorId) {
    return [
      {
        name: 'operator_id',
        required: true,
        display_name: 'Operator ID',
        type: 'hidden',
      },
      {
        name: 'affiliate_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website',
        value_key: 'affiliate.website',
        notify_fields: [{ field: 'ribbons', param: 'site_id' }],
        notify_extra_fields: {
          param: 'affiliate_id',
          wait_for: ['operator_id', 'affiliate_id', 'type', 'market_id'],
        },
        editable: false,
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        type: 'list-one',
        selectLabel: 'label',
        value_key: 'market.label',
        country_flag: 'country_code',
        notify_fields: [
          { field: 'selling_points', param: 'market_id' },
          { field: 'ribbons', param: 'market_id' },
        ],
        notify_extra_fields: {
          param: 'affiliate_id',
          wait_for: ['operator_id', 'affiliate_id', 'type', 'market_id'],
        },
      },
      {
        name: 'type',
        search_url: `hercules/operators/game-types?operator_id=${operatorId}`,
        required: true,
        display_name: 'Type',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'type',
        notify_extra_fields: {
          param: 'affiliate_id',
          wait_for: ['operator_id', 'affiliate_id', 'type', 'market_id'],
        },
        notify_fields: [
          { field: 'first_purchase_discount_id', param: 'type' },
          { field: 'info_message_id', param: 'type' },
          { field: 'compliance_id', param: 'type' },
          { field: 'ssl_encryption_id', param: 'type' },
        ],
        help_text: 'Type not visible? Make sure it is enabled in the general data tab (operator types)',
      },
      {
        name: 'currencies',
        required: false,
        search_url: 'hercules/globals/currencies?orderBy=name&ascending=1&with_no_assets=1',
        display_name: 'Currencies',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        help_text:
          'Please use Markets Data currencies unless absolutely necessary to use site-specific currencies',
      },
      {
        name: 'status',
        required: false,
        display_name: 'Status',
        type: 'list-one-simple',
        options: ['coming_soon', 'active', 'inactive', 'restricted', 'blacklisted', 'not_recommended'],
        trackBy: null,
        selectLabel: null,
      },
      {
        name: 'scheduled',
        required: false,
        type: 'checkbox',
      },
      {
        name: 'scheduled_status',
        type: 'list-one-simple',
        options: ['coming_soon', 'active', 'inactive', 'restricted', 'blacklisted', 'not_recommended'],
        trackBy: null,
        required: true,
        selectLabel: null,
        show_if: { field: 'scheduled', value: [true, 1] },
      },
      {
        name: 'scheduled_at',
        type: 'date',
        display_name: 'Scheduled Date',
        required: true,
        show_if: { field: 'scheduled', value: [true, 1] },
        config: {
          date: moment().format('YYYY-MM-DD'),
          altInput: true,
          altFormat: 'd/m/Y',
          dateFormat: 'Y-m-d',
          time_24hr: false,
          enableTime: false,
          minDate: moment().add(1, 'days').format(),
        },
      },
      {
        name: 'note',
        required: false,
        display_name: 'Note',
        type: 'textarea',
      },
      { name: 'review_link', type: 'text', required: false, display_name: 'Review Link', validation: '' },
      { name: 'video_link', type: 'text', required: false, display_name: 'Video Link', validation: '' },
      { name: 'logo_url', type: 'hidden', required: false, display_name: 'Logo URL', validation: '' },
      {
        name: 'logo_asset_id',
        required: false,
        display_name: 'Logo',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
        accepted_types: 'image/jpeg,image/png',
        validation: '',
        substitute: 'image',
      },
      {
        name: 'animation_asset_id',
        required: false,
        display_name: 'Animation',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
        accepted_types: 'image/jpeg,image/png',
        validation: '',
        substitute: 'image',
      },
      {
        name: 'opt_logo_asset_id',
        required: false,
        display_name: 'Optional Logo',
        type: 'hidden',
        accepted_types: 'image/jpeg,image/png',
        max_size: 300000,
        validation: '',
        substitute: 'image',
      },
      {
        name: 'rating',
        required: false,
        display_name: 'Rating',
        type: 'average_number',
        average_input: 'rating',
        inputs: ['rating_casino', 'rating_games', 'rating_bonuses', 'rating_customer', 'rating_payout'],
        min: 0,
        max: 5,
        step: 0.1,
      },
      {
        name: 'payout_time',
        type: 'text',
        required: false,
        display_name: 'Payout Time',
      },
      {
        name: 'per_site_enabled',
        required: false,
        display_name: 'Per Site Enabled',
        type: 'hidden',
        validation: '',
      },
      {
        name: 'selling_points',
        search_url: `hercules/globals/selling-points`,
        display_name: 'Selling Points',
        display_field: 'name',
        extraSelectLabel: 'translation',
        stylizedExtraLables: true,
        type: 'list-many',
        is_sites_data: true,
        quick_add: { model: 'SellingPoint', service: 'SellingPointsService' },
        notified_by: [
          {
            field: 'market_id',
            label: 'Market',
            param: 'market_id',
          },
        ],
      },
      {
        name: 'ribbons',
        search_url: `hercules/globals/ribbons`,
        display_name: 'Ribbons',
        selectLabel: 'label',
        type: 'list-many',
        validate_changes: true,
        fetch_on_load: false,
        notified_by: [
          {
            field: 'affiliate_id',
            request_field_name: 'site_id',
            label: 'Site',
          },
          {
            field: 'market_id',
            label: 'Market',
          },
        ],
        quick_add: { model: 'Ribbons', service: 'RibbonsService' },
        max: 3,
      },
      {
        name: 'restricted_countries',
        search_url:
          'hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1',
        display_field: 'name',
        type: 'list-many-nested-checkbox',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'pros',
        display_name: 'Pros',
        selectLabel: 'name',
        type: 'list-many',
        search_url: 'hercules/globals/pros-cons?type=pro',
        fetch_on_load: false,
        validate_changes: true,
        notified_by: [
          {
            field: 'affiliate_id',
            request_field_name: 'site_id',
            label: 'Site',
          },
          {
            field: 'market_id',
            label: 'Market',
          },
        ],
      },
      {
        name: 'cons',
        display_name: 'Cons',
        selectLabel: 'name',
        type: 'list-many',
        search_url: 'hercules/globals/pros-cons?type=con',
        validate_changes: true,
        fetch_on_load: false,
        notified_by: [
          {
            field: 'affiliate_id',
            request_field_name: 'site_id',
            label: 'Site',
          },
          {
            field: 'market_id',
            label: 'Market',
          },
        ],
      },
      {
        name: 'game_filters',
        display_name: 'Game Filters',
        type: 'radio',
        values: [
          {value: 1, label: 'Yes'},
          {value: 0, label: 'No'},
          {value: 2, label: 'None'},
        ]
      },
      {
        name: 'sports_filters',
        display_name: 'Sports Filters',
        type: 'radio',
        values: [
          {value: 1, label: 'Yes'},
          {value: 0, label: 'No'},
          {value: 2, label: 'None'},
        ]
      },
      {
        name: 'search_function',
        display_name: 'Search Function',
        type: 'radio',
        values: [
          {value: 1, label: 'Yes'},
          {value: 0, label: 'No'},
          {value: 2, label: 'None'},
        ]
      },
      {
        name: 'load_speed',
        type: 'number',
        display_name: 'Load Speed',
      },
      {
        name: 'web_ux',
        type: 'list-one-simple',
        display_name: 'Web UX',
        options: ['Poor', 'Fair', 'Neutral', 'Good', 'Excellent'],
      },
      {
        name: 'mobile_ux',
        type: 'list-one-simple',
        display_name: 'Mobile UX',
        options: ['Poor', 'Fair', 'Neutral', 'Good', 'Excellent'],
      },
      {
        name: 'compliance_id',
        display_name: 'Compliance',
        search_url: 'hercules/operators/compliance',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
        notified_by: { field: 'type', request_field_name: 'type', request_field_name_parameter: 'type' },
      },
      {
        name: 'info_message_id',
        display_name: 'Info Message',
        search_url: 'hercules/operators/info-message',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
        notified_by: { field: 'type', request_field_name: 'type', request_field_name_parameter: 'type' },
      },
      {
        name: 'first_purchase_discount_id',
        display_name: 'First Purchase Discount',
        search_url: 'hercules/operators/first-purchase-discount',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
        notified_by: { field: 'type', request_field_name: 'type', request_field_name_parameter: 'type' },
      },
      {
        name: 'free_reward_id',
        display_name: 'Free Reward',
        search_url: 'hercules/operators/free-reward',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
      },
      {
        name: 'redeemable_prize_id',
        display_name: 'Redeemable Prize',
        search_url: 'hercules/operators/redeemable-prize',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
      },
      {
        name: 'ever_blacklisted',
        required: false,
        display_name: 'Ever Blacklisted',
        type: 'radio',
        values: [
          {value: 1, label: 'Yes'},
          {value: 0, label: 'No'},
          {value: 2, label: 'None'},
        ]
      },
      {
        name: 'ssl_encryption_id',
        display_name: 'SSL Encryption',
        search_url: 'hercules/operators/ssl-encryption',
        required: false,
        type: 'list-one',
        selectLabel: 'name',
        notified_by: { field: 'type', request_field_name: 'type', request_field_name_parameter: 'type' },
      }
    ]
  },
  getConfig() {
    return {
      url: 'operators/affiliate-operators',
      name: 'Site Data',
      editWithData: 'affiliate,market,selling_points,asset_details,ribbons,currencies,restricted_countries,pros,cons',
    }
  },
  getStructure(operatorId) {
    return {
      list: this.getForm(operatorId),
      config: this.getConfig(),
    }
  },
  getExtraFields(id) {
    let self = this
    return new Promise((resolve) => {
      if (id) {
        AffiliateExtraFieldsService.getAll(id)
          .then((response) => {
            const extraFields = []
            if (response.data.success) {
              response.data.result.forEach((extraField) => {
                extraField.display_name = self.prettifyLabel(extraField.name)
                extraField.required = extraField.required == 1 ? true : false
                extraField.ref = 'extra_field'
                extraField.model_type = 'operator'
                extraFields.push(extraField)
              })
            }

            resolve(extraFields)
          })
          .catch(self.showUnknownErrorMessage)
      } else {
        resolve([])
      }
    })
  },
  getExtraFieldsValues(id) {
    let self = this
    return new Promise((resolve) => {
      if (id) {
        AffiliateExtraFieldValuesService.get(id, {
          with: 'extra_field,formatted_values,variables',
        })
          .then((response) => {
            const extraFields = []
            if (response.data.success) {
              response.data.result.forEach((extraValue) => {
                let extraField = extraValue.extra_field
                extraField.display_name = self.prettifyLabel(extraField.name)
                extraField.required = extraField.required == 1 ? true : false
                extraField.ref = 'extra_field'
                extraField.affiliate_operator_id = extraValue.affiliate_operator_id
                extraField.extra_value_id = extraValue.id
                extraField.model_type = 'operator'
                extraField.value = extraValue.value
                if (extraField.type === 'number') {
                  extraField.type = 'custom-number'
                  extraField.step = '0.1'
                }
                extraFields.push(extraField)
              })
            }

            resolve(extraFields)
          })
          .catch(self.showUnknownErrorMessage)
      } else {
        resolve([])
      }
    })
  },
  getTopExampleFields() {
    return [
      {
        name: 'example_top_game',
        display_name: 'Example Top Game',
        required: false,
        type: 'text',
      },
      {
        name: 'example_top_table_game',
        display_name: 'Example Top Table Game',
        required: false,
        type: 'text',
      },
      {
        name: 'example_top_jackpot_game',
        display_name: 'Example Top Jackpot Game',
        required: false,
        type: 'text'
      },
      {
        name: 'example_top_slot_game',
        display_name: 'Example Top Slot Game',
        required: false,
        type: 'text'
      },
      {
        name: 'example_top_live_game',
        display_name: 'Example Top Live Game',
        required: false,
        type: 'text'
      },
      {
        name: 'example_top_software',
        display_name: 'Example Of Top Software',
        required: false,
        type: 'text'
      },
      {
        name: 'example_top_live_software_provider',
        display_name: 'Example Top Live Software Provider',
        required: false,
        type: 'text'
      }
    ]
  },
  getAdvancedRatingValues(affiliateOperatorid, affiliateId, marketId, operatorType) {
    let self = this
    return new Promise((resolve) => {
      if (affiliateId) {
        AffiliateAdvancedRatingValuesService.get(affiliateOperatorid, affiliateId, marketId, operatorType)
          .then((response) => {
            const advancedRatings = []
            if (response.data.success) {
              response.data.result.forEach((advancedRatingValue) => {
                let advancedRating = advancedRatingValue.advanced_rating
                advancedRating.name = advancedRating.rating_name
                advancedRating.ref = 'advanced_rating'
                advancedRating.type = 'custom-number'
                ;(advancedRating.step = '0.1'),
                  (advancedRating.affiliate_operator_id = advancedRatingValue.affiliate_operator_id)
                advancedRating.value = advancedRatingValue.value
                advancedRating.advanced_rating_id = advancedRatingValue.id
                advancedRatings.push(advancedRating)
              })
            }
            resolve(advancedRatings)
          })
          .catch(self.showUnknownErrorMessage)
      } else {
        resolve([])
      }
    })
  },
  prettifyLabel(value) {
    let fixed = value.replace(/_|-/g, ' ')
    return fixed[0].toUpperCase() + fixed.slice(1)
  },
}
