<template>
  <div>
    <div class="row">
      <div class="col">
        <pages-sidebar @filterChanged="filterChanged" ref="sidebar" />
        <data-table
          model="cms/Page"
          service-file="PagesService"
          :path="getPath()"
          post-create-navigation-route="site-pages"
          :columns="columns()"
          :column-templates="columnTemplates()"
          :create-defaults="{ status: 'inactive', ...createDefaults }"
          :loadOnMount="false"
          :show-remove="true"
          :show-edit-modal="false"
          @bulkClickByIds="bulkClick"
          :enableBulkSelect="!hasPermission('misc.sites/limited-to-own-pages')"
          :extraDeleteInfo="{
            title: 'Warning',
            message: '',
            extraMessage: 'Please check redirects associated with this page.',
            boldText: 'font-weight-bold',
          }"
          ref="table"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
      <page-edit-bulk
        v-if="showBulkEdit"
        ref="bulkEdit"
        :siteId="siteId"
        @refresh="$refs.table.search()"
        @closed="() => (showBulkEdit = false)"
      />
    </div>
  </div>
</template>

<script lang="jsx">
import PagesSidebar from '@molecules/cms/pages/site-page-filter.vue'
import PageEditBulk from '@molecules/cms/pages/edit-bulk.vue'
import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import SiteMarketsService from '@services/SiteMarketsService.js'
import dateFormat from '@/filters/CustomFilters.js'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
      siteId: null,
      createDefaults: {},
      showBulkEdit: false,
    }
  },
  components: { PagesSidebar, PageStatusBadge, PageEditBulk },
  methods: {
    bulkClick(ids) {
      if (ids.length === 1) {
        this.$router.push(`/site-pages/${ids[0]}`)
      } else if (ids.length > 1) {
        this.showBulkEdit = true
        this.$nextTick(() => {
          this.$refs.bulkEdit?.show(ids)
        })
      }
    },
    filterChanged(filter) {
      this.siteId = filter.site_id
      this.createDefaults = {
        site_id: filter.site_id,
        template_id: filter.template_id,
        categories: filter.categories,
        status: 'inactive',
      }
      if (filter.market_id) {
        SiteMarketsService.get({ market_id: filter.market_id, site_id: filter.site_id }).then((response) => {
          if (response.data.result.length === 1) {
            this.createDefaults.site_market_id = response.data.result[0].id
          }
        })
      }
      this.$store.commit('data-table/setActivePage', 1)
      this.$store.dispatch('data-table/setSiteFilters', filter)
    },
    getPath() {
      return 'hercules/sites/pages?with=market,template,categories'
    },
    columns() {
      return [
        { field: 'title', type: 'text', filterable: true, template: TableColumnTemplate.TEXT_TRUNCATE },
        { field: 'path', type: 'text', filterable: true, template: TableColumnTemplate.CLIPBOARD },
        { field: 'template', type: 'text', label: 'Page type', filterable: true },
        { field: 'market', type: 'text', label: 'Market', filterable: true },
        {
          field: 'updated_at',
          label: 'Updated',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
        { field: 'created_at', label: 'Published', type: 'text', filterable: true },
        { field: 'status', type: 'text', label: 'Status', filterable: true },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          status: function (row) {
            return <PageStatusBadge page={row} minimal={true} />
          },
        },
        {
          template: function (row) {
            return row.template ? row.template.name : ''
          },
        },
        {
          market: function (row) {
            return row.market.label
          },
        },
        {
          created_at: function (row) {
            if (row.status === 'scheduled') {
              const scheduledAt = new Date(row.scheduled_at)
              const currentDate = new Date()
              if (scheduledAt > currentDate) {
                return (
                  <div>
                    {dateFormat.date(row.created_at)} <i class="gig gig-clock"></i>
                  </div>
                )
              }
            }
            return dateFormat.date(row.created_at)
          },
        }
      )
    },
    showRemoveAllowedChecker(page) {
      if (this.hasPermission('misc.sites/limited-to-own-pages')) {
        return this.$auth.user().id === page.created_by_user_id
      }
      return true
    },
  },
  unmounted() {
    this.$store.dispatch('data-table/setSiteFilters', {})
  },
}
</script>
