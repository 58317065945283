<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ activeTab }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: currentTab === 'comment' }" @click="switchTab('comment')"
                >Comments</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: currentTab === 'replies' }" @click="switchTab('replies')"
                >Replies</a
              >
            </li>
          </ul>
          <div class="tab-content mt-3">
            <CommentsTab
              v-if="currentTab === 'comment' && row"
              :row="row"
              @closeModal="close"
              :siteId="siteId"
            />
            <RepliesTab
              v-if="currentTab === 'replies' && row"
              :replies="row.replies"
              :siteId="siteId"
              @closeModal="close"
              @replyChanged="handleReplyChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import CommentsTab from '../comments/comments-tab.vue'
import RepliesTab from '../comments/replies-tab.vue'

const emits = defineEmits(['replyChanged'])
const props = defineProps({
  row: {
    type: Object,
    default: () => ({}),
    required: false,
  },
  siteId: {
    type: Number,
    default: () => null,
  },
})

const currentTab = ref('comment')
const activeTab = ref('Edit Comments')

const switchTab = (tab) => {
  currentTab.value = tab
  activeTab.value = tab === 'comment' ? 'Edit Comments' : 'Add Replies'
}
const modal = ref(null)

onMounted(() => {
  document.getElementById('modal').appendChild(modal.value)
})

const show = () => {
  modal.value.classList.add('show')
}

const close = () => {
  modal.value?.classList.remove('show')
  currentTab.value = 'comment'
}

const handleReplyChange = (reply) => {
  emits('replyChanged', reply)
}

// Expose methods that need to be accessed from parent components
defineExpose({
  show,
})
</script>
<style scoped>
.modal-dialog {
  max-width: 960px !important;
}
</style>
