import Api from './Api'

export default {
  get(affiliateOperatorId, affiliateId, market_ids, operator_type) {
    return Api().get(`hercules/operators/affiliate-operators/advanced-rating-values`, {
      params: {
        with: 'advanced_rating',
        affiliate_operator_id: affiliateOperatorId,
        affiliate_id: affiliateId,
        market_ids: [market_ids],
        operator_type: [operator_type],
      },
    })
  },
  getOne(affiliateOperatorId, fieldId, data) {
    return Api().get(
      `hercules/operators/affiliate-operators/${affiliateOperatorId}/advanced-rating-values/${fieldId}`,
      {
        params: data,
      }
    )
  },
  update(affiliateOperatorId, data) {
    return Api().put(
      `hercules/operators/affiliate-operators/${affiliateOperatorId}/advanced-rating-values/${data.id}`,
      data
    )
  },
  getCreate(affiliateId, market_ids, operator_type) {
    return Api().get(`hercules/operators/affiliate-advanced-rating-values`, {
      params: {
        with: 'advanced_rating',
        affiliate_id: affiliateId,
        market_ids: [market_ids],
        operator_type: [operator_type],
      },
    })
  },
}
