<template>
  <data-table
    model="GameCategory"
    service-file="GameCategoriesService"
    :path="path()"
    :columns="columns()"
    :rowActionButtons="rowActions()"
    :show-remove="false"
    ref="list"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import PagesService from '@services/PagesService.js'
import GameCategoriesService from '@services/GameCategoriesService.js'

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}

export default {
  created() {
    this.setTitle()
  },
  methods: {
    path() {
      return 'hercules/globals/game-categories'
    },
    rowActions() {
      if (this.methodAllowed('delete', this.path())) {
        return [
          {
            action: this.remove,
            title: 'Delete',
            icon: 'uil uil-trash-alt',
            class: 'btn-danger',
          },
          {
            action: this.translationAction,
            title: 'Translations',
            icon: 'uil uil-adjust',
            class: 'btn-info',
          },
        ]
      }
    },
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    async checkPageRelations(id) {
      try {
        const response = await PagesService.get({ relation_type: 'game_category', relation_id: id })
        if (response.data.result) {
          this.pageRelations = response.data.result
        } else {
          console.error('No page relations found or error in response:', response)
          this.pageRelations = []
        }
      } catch (error) {
        console.error('Error fetching page relations:', error)
        this.showErrorMessage('Error fetching page relations')
      }
    },
    async remove(row) {
      await this.checkPageRelations(row.id)

      try {
        let htmlForPageRelations = ''

        if (this.pageRelations.length > 0) {
          const relatedPageIds = this.pageRelations
            .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
            .join('<br>')

          htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
        } else {
          htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
        }

        const confirm = await confirmDelete({
          title: 'Warning',
          content: 'Are you sure you want to delete this item?',
          htmlForPageRelations: htmlForPageRelations,
          actionButtonTitle: 'Delete item',
        })

        if (confirm) {
          this.loading = true

          if (row.id) {
            const deleteResponse = await GameCategoriesService.remove({ id: row.id })

            if (deleteResponse.data?.messages) {
              this.showErrorMessages(deleteResponse.data.messages)
            } else {
              this.showSuccessMessage('Item removed successfully.')
            }
          }
        }
      } catch (error) {
        console.error('Error removing item:', error)
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
        this.refresh()
      }
    },
    translationAction(row) {
      let routeData = this.$router.resolve('translations?key=' + row.short_name)
      window.open(routeData.href, '_blank')
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
